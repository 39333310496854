import React from 'react';
import './Label.css';

export interface LabelProps {
   className: string;
   children: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({ className, children }) => {
   return <div className={`label text-white bg-danger ${className ? className : ''}`}>{children}</div>;
};

export default React.memo(Label, (prevProps, nextProps) => {
   return nextProps.className === prevProps.className;
});
