import { AbilityBuilder, Ability } from '@casl/ability';
import { getCurrentUser } from 'services/authentication.service';

const user = getCurrentUser();
let ability = new Ability([]);

type Action = 'FILTER_BY_ZONA' | 'EDIT_OR_CREATE_CLAVE_ZONA';
export function hasPermissionTo(action: Action): boolean {
   const user = getCurrentUser();
   if (action === 'FILTER_BY_ZONA' && [3, 4, 6].includes(user.tipo)) return true;
   if (action === 'EDIT_OR_CREATE_CLAVE_ZONA' && [4, 6].includes(user.tipo)) return true;
   return false;
}

export function updateAbilities() {
   const user = getCurrentUser();
   defineAbilitiesFor(user);
}

const secciones = [
   'seccion_monitor',
   'seccion_choferes',
   'seccion_grupos',
   'seccion_viajes',
   'seccion_clientes',
   'seccion_quejas',
   'seccion_saldos',
   'seccion_zonas',
   'seccion_reportes',
   'seccion_registros'
];
function defineAbilitiesFor(user) {
   const { can: allow, rules, cannot: forbid } = AbilityBuilder.extract();
   if (!user) {
      return ability;
   }
   switch (user.tipo) {
      case 1:
         // Super Admin
         allow('access', [...secciones, 'seccion_usuarios', 'seccion_config']);
         allow('filterByZona', 'Viajes');
         allow('create', 'Zona');
         break;
      case 2:
         // Administrador kargo
         allow('access', [...secciones, 'seccion_usuarios']);
         allow('filterByZona', 'Viajes');
         allow('create', 'Zona');
         break;
      case 3:
         // Administrador zona
         allow('access', [...secciones, 'seccion_usuarios']);
         forbid('filterByZona', 'Viajes');
         break;
      case 4:
         // Administrador
         allow('access', [...secciones, 'seccion_usuarios']);
         break;
      case 6: // Operadores
         allow('access', secciones);
         break;
   }
   return ability.update(rules);
}

export default defineAbilitiesFor(user);
