import React from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from 'mdbreact';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import SelectInput from 'components/shared/SelectInput';
import { hasPermissionTo } from 'services/authorization.service';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import './InformacionZona.css';
// Mis Types
import Zona, { UsuarioOptions } from 'models/Zonas';
import { Pagination } from 'typings/Tablas';

interface InformacionZonaProps {
   zona: Zona;
   grupo: any[];
   usuarios: UsuarioOptions[];
   administradoresZona: any[];
   onAddMiembro: () => void;
   onRemoveMiembro: (id: number) => void;
   // isTableLoading: boolean;
   pagination: Pagination;
   onTableChange: (type: any, { page, sizePerPage }: Pagination) => void;
   fetchSearchUsuarios: (query: string) => Promise<void>;
   onSelectOption: ((selected: UsuarioOptions[]) => void) | undefined;
   onStepSubmit: (values, actions) => void;
}

const InformacionZona: React.FC<InformacionZonaProps> = ({
   zona,
   grupo,
   usuarios,
   administradoresZona,
   onAddMiembro,
   onRemoveMiembro,
   // isTableLoading,
   pagination,
   onTableChange,
   fetchSearchUsuarios,
   onSelectOption,
   onStepSubmit
}) => {
   const initialValues = { ...zona };
   const informacionZonaSchema = Yup.object().shape({
      nombreZona: Yup.string().required('Campo requerido'),
      idUsuarioAdmin: Yup.number()
         .nullable()
         .required('Campo requerido'),
      prefijoChofer: Yup.string()
         .matches(/^[A-Z]{2,3}$/, 'Solo letras mayúsculas. De 2 a 3 letras.')
         .required('Campo requerido')
   });

   const optionsFormatter = (cell, row) => {
      return (
         <MDBIcon className='text-danger cursor-pointer' size='2x' icon='times' onClick={() => onRemoveMiembro(row.idUsuario)} />
      );
   };
   const columns = [
      {
         dataField: 'idUsuario',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombre',
         text: 'Nombre',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'email',
         text: 'Correo electrónico',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'rol',
         text: 'Rol',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'options',
         text: 'Opciones',
         isDummyField: true,
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: optionsFormatter
      }
   ];

   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         validationSchema={informacionZonaSchema}
         onSubmit={onStepSubmit}
         render={({ setFieldValue }: FormikProps<Zona>) => (
            <Form>
               <p className='text-muted mb-1 font-size-18'>Información básica</p>

               <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                     <Field
                        name='nombreZona'
                        render={({ field }) => <MDBInput {...field} outline label='Nombre de zona' type='text' />}
                     />
                     <ErrorMessage name='nombreZona' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='prefijoChofer'
                        render={({ field }) => (
                           <MDBInput
                              {...field}
                              outline
                              label='Clave de zona'
                              type='text'
                              disabled={hasPermissionTo('EDIT_OR_CREATE_CLAVE_ZONA')}
                           />
                        )}
                     />
                     <ErrorMessage name='prefijoChofer' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='idUsuarioAdmin'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Elegir Administrador'
                              options={administradoresZona}
                              isSearchable={false}
                              setFieldValue={setFieldValue}
                           />
                        )}
                     />
                     <ErrorMessage name='idUsuarioAdmin' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               <hr />
               <p className='text-muted mt-4 mb-1 font-size-18'>Buscar miembros para agregar a la zona</p>
               <MDBRow className='mb-3'>
                  <MDBCol md='8'>
                     <AsyncTypeahead
                        id={1}
                        placeholder='Buscar por nombre, correo, teléfono, ID'
                        promptText='Escriba para buscar'
                        emptyLabel='No se encontraron resultados'
                        maxResults={10}
                        minLength={3}
                        delay={500}
                        isLoading={false}
                        onSearch={fetchSearchUsuarios}
                        onChange={onSelectOption}
                        labelKey={(option: UsuarioOptions) =>
                           `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`
                        }
                        clearButton={true}
                        options={usuarios}
                     />
                  </MDBCol>
                  <MDBCol md='4'>
                     <div style={{ marginTop: '0.6rem' }}>
                        <MDBBtn color='primary' className='text-capitalize btn-top-action' onClick={onAddMiembro}>
                           <MDBIcon className='mr-2' icon='plus' size='lg' />
                           Agregar
                        </MDBBtn>
                     </div>
                  </MDBCol>
               </MDBRow>

               <hr />
               <p className='text-muted mt-4 mb-3 font-size-18'>Miembros de la zona</p>

               <BootstrapTable
                  bootstrap4
                  condensed
                  classes='grupo-table'
                  keyField='idUsuario'
                  data={grupo}
                  columns={columns}
                  remote={{
                     pagination: true
                  }}
                  // loading={isTableLoading}
                  overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
                  hover
                  bordered={false}
                  noDataIndication='No se encontraron registros.'
                  rowClasses={'font-weight-bold'}
                  pagination={paginationFactory({
                     page: pagination.page, // Specify the current page.
                     totalSize: pagination.totalSize, // Total data size.
                     sizePerPage: pagination.sizePerPage, // Specify the size per page.
                     withFirstAndLast: false, // hide the going to first and last page button
                     alwaysShowAllBtns: true, // always show the next and previous page button
                     prePageText: 'Anterior',
                     nextPageText: 'Siguiente',
                     hideSizePerPage: true, // hide the size per page dropdown
                     hidePageListOnlyOnePage: true
                  })}
                  onTableChange={onTableChange}
               />

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default InformacionZona;
