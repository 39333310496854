import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import QuejasLista from 'components/quejas/QuejasLista';

export interface QuejasPageProps {}

const QuejasPage: React.FC<QuejasPageProps> = () => {
   return (
      <section id='quejas'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar quejas</h3>
               </header>
            </MDBCol>
         </MDBRow>

         {/* TABLA DE QUEJAS */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <QuejasLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default QuejasPage;
