import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBInput, MDBAlert } from "mdbreact";
import { Formik, Field, Form, ErrorMessage, FormikActions, FormikProps } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import ErrorFeedback from "components/shared/ErrorFeedback";
import http from "services/http.service";
import apiErrorHandler from "services/apiErrorHandler.service";
import { mapOptionsToViewModel } from "utils";
import { getCurrentUser } from "services/authentication.service";
// Mis Types
import { Option } from "typings/General";
import Usuario from "models/Usuarios";

const InitialState: Usuario = {
  nombres: "",
  primerApellido: "",
  segundoApellido: "",
  email: "",
  telefono: "",
  password: "",
  confirmPassword: "",
  idTipoUsuario: null,
  idZona: null,
};

export interface FormUsuarioProps extends RouteComponentProps {}

const FormUsuario: React.FC<FormUsuarioProps> = ({ match, history }) => {
  const user = getCurrentUser();
  // FORM STATE
  const usuarioId = match.params["id"];
  const [usuario, setUsuario] = useState<Usuario>({
    ...InitialState,
  });

  const [tipoUsuarioOptions, setTipoUsuarioOptions] = useState<Option[]>([]);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);

  const usuarioSchema = Yup.object().shape({
    nombres: Yup.string().required("Campo requerido"),
    primerApellido: Yup.string().required("Campo requerido"),
    segundoApellido: Yup.string().required("Campo requerido"),
    email: Yup.string().email("Escriba un email válido").required("Campo requerido"),
    telefono: Yup.string()
      .matches(/^\d{10}$/, "Ingrese un teléfono válido")
      .required("Campo requerido"),
    ...(usuarioId
      ? {
          password: Yup.string().min(6, "Mínimo 6 caracteres").max(50, "Maximo 50 caracteres"),
          confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
        }
      : {
          password: Yup.string().min(6, "Mínimo 6 caracteres").max(50, "Maximo 50 caracteres").required("Campo requerido"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
            .required("Campo requerido"),
        }),
    idTipoUsuario: Yup.number().nullable().required("Campo requerido"),
    idZona: Yup.number().when("idTipoUsuario", {
      is: idTipoUsuario => {
        if ([4, 6].includes(idTipoUsuario)) return true;
      },
      then: Yup.number().nullable().required("Campo requerido"),
      otherwise: Yup.number().nullable(),
    }),
  });

  // Obtener datos para los Selects
  useEffect(() => {
    const fetchTiposUsuario = async () => {
      try {
        const params = {
          activo: true,
        };
        const { rows }: any = await http.get("catalogos/TiposUsuario", { params });
        setTipoUsuarioOptions(mapOptionsToViewModel(rows));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los tipos de usuario, recargue la página.</ToastMessage>);
      }
    };

    const fetchZonas = async () => {
      try {
        const params = {
          activo: true,
        };
        const { rows }: any = await http.get("zonas", { params });
        setZonasOptions(mapOptionsToViewModel(rows));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar las zonas, recargue la página.</ToastMessage>);
      }
    };

    fetchTiposUsuario();
    fetchZonas();
  }, []);

  // Obtener datos para editar usuario
  useEffect(() => {
    const fetchUsuario = async () => {
      try {
        const usuarioData: any = await http.get(`usuarios/${usuarioId}`);
        populateUsuario(usuarioData);
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos de usuario, intente de nuevo.</ToastMessage>);
        }
      }
    };

    const populateUsuario = usuario => {
      setUsuario({
        nombres: usuario.nombres,
        primerApellido: usuario.primerApellido,
        segundoApellido: usuario.segundoApellido,
        email: usuario.email,
        telefono: usuario.telefono,
        password: "",
        idTipoUsuario: usuario.tipoUsuario.idTipoUsuario,
        idZona: usuario.zona ? usuario.zona.idZona : null,
      });
    };

    if (usuarioId) {
      fetchUsuario();
    }
  }, [usuarioId]);

  const handleFormSubmit = async (values: Usuario, actions: FormikActions<Usuario>) => {
    try {
      usuarioId ? await updateUsuario(values) : await createUsuario(values, actions);
    } catch (error) {
      actions.setSubmitting(false);
      apiErrorHandler("Usuario", error);
    }
  };

  const updateUsuario = async (values: Usuario) => {
    const body = setBody(values);
    await http.put(`usuarios/${usuarioId}`, body);

    const result = await Swal.fire({
      title: "Datos actualizados",
      text: "Los cambios han sido actualizados correctamente.",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-info waves-effect waves-light text-capitalize",
      },
      buttonsStyling: false,
    });
    if (result) {
      history.push(`/r/usuarios`);
    }
  };

  const createUsuario = async (values: Usuario, actions: FormikActions<Usuario>) => {
    const body = setBody(values);
    const newUsuario: any = await http.post("usuarios", body);

    const result = await Swal.fire({
      title: "Usuario guardado!",
      text: "El nuevo usuario ha sido guardado.",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Crear otro Usuario",
      cancelButtonText: "Ver detalles",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-info waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-info waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      // Reset form
      setUsuario({ ...InitialState });
      actions.resetForm();
      actions.setSubmitting(false);
      return;
    }
    history.push(`/r/usuarios/${newUsuario.idUsuario}/detalles`);
  };

  const setBody = (usuarioUpdate: Usuario) => {
    const body: object = {};
    for (const [key, value] of Object.entries(usuario)) {
      if (value !== usuarioUpdate[key] && key !== "confirmPassword") {
        if (typeof usuarioUpdate[key] === "string") {
          body[key] = usuarioUpdate[key].trim();
        } else {
          body[key] = usuarioUpdate[key];
        }
      }
    }
    return body;
  };

  if (usuarioId && !usuario.idTipoUsuario && !usuario.idZona) {
    return null;
  }

  return (
    <section id="usuarios">
      <MDBRow className="mb-4 align-items-center">
        <MDBCol>
          <header>
            <h3 className="mb-0 text-center">
              <Link className="text-dark" to="/r/usuarios">
                <MDBIcon className="mr-3" icon="arrow-left" />
              </Link>
              {usuarioId ? "Editar Usuario" : "Nuevo Usuario"}
            </h3>
          </header>
        </MDBCol>
      </MDBRow>
      <div className="container">
        <MDBCard className="mx-auto" style={{ maxWidth: "40rem" }}>
          <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold">
            <MDBIcon size="lg" icon="user-plus" className="mr-3" />
            {usuarioId ? "Editar usuario de panel Kargo" : "Crear usuario de panel Kargo"}
          </div>
          <MDBCardBody className="p-4">
            <Formik
              initialValues={usuario}
              validationSchema={usuarioSchema}
              onSubmit={handleFormSubmit}
              render={({ values, isSubmitting, setFieldValue }: FormikProps<Usuario>) => (
                <Form className="usuario-form">
                  <Field name="nombres" render={({ field }) => <MDBInput outline label="Nombres" type="text" {...field} />} />
                  <ErrorMessage name="nombres" component={ErrorFeedback} />

                  <MDBRow>
                    <MDBCol md="6">
                      <Field
                        name="primerApellido"
                        render={({ field }) => <MDBInput {...field} outline label="Apellido Paterno" type="text" />}
                      />
                      <ErrorMessage name="primerApellido" component={ErrorFeedback} />
                    </MDBCol>
                    <MDBCol md="6">
                      <Field
                        name="segundoApellido"
                        render={({ field }) => <MDBInput {...field} outline label="Apellido Materno" type="text" />}
                      />
                      <ErrorMessage name="segundoApellido" component={ErrorFeedback} />
                    </MDBCol>
                  </MDBRow>

                  <Field name="email" render={({ field }) => <MDBInput outline label="Correo electrónico" type="text" {...field} />} />
                  <ErrorMessage name="email" component={ErrorFeedback} />

                  <Field name="telefono" render={({ field }) => <MDBInput {...field} outline label="Teléfono" type="text" />} />
                  <ErrorMessage name="telefono" component={ErrorFeedback} />

                  {!usuarioId && (
                    <MDBRow>
                      <MDBCol md="6">
                        <Field name="password" render={({ field }) => <MDBInput outline label="Contraseña" type="password" {...field} />} />
                        <ErrorMessage name="password" component={ErrorFeedback} />
                      </MDBCol>
                      <MDBCol md="6">
                        <Field
                          name="confirmPassword"
                          render={({ field }) => <MDBInput {...field} outline label="Repetir contraseña" type="password" />}
                        />
                        <ErrorMessage name="confirmPassword" component={ErrorFeedback} />
                      </MDBCol>
                    </MDBRow>
                  )}

                  {usuarioId && [1, 2, 3, 4].includes(user.tipo) && (
                    <>
                      <MDBRow>
                        <MDBCol md="6">
                          <Field
                            name="password"
                            render={({ field }) => <MDBInput outline label="Contraseña" type="password" {...field} />}
                          />
                          <ErrorMessage name="password" component={ErrorFeedback} />
                        </MDBCol>
                        <MDBCol md="6">
                          <Field
                            name="confirmPassword"
                            render={({ field }) => <MDBInput {...field} outline label="Repetir contraseña" type="password" />}
                          />
                          <ErrorMessage name="confirmPassword" component={ErrorFeedback} />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-3">
                        <MDBCol>
                          <MDBAlert className="mb-0" color="warning">
                            <MDBIcon icon="exclamation-triangle mr-2" size="lg" />
                            Deje el campo Contraseña en blanco sino desea cambiar la contraseña actual.
                          </MDBAlert>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}

                  <Field
                    name="idTipoUsuario"
                    render={({ field }) => (
                      <SelectInput
                        {...field}
                        placeholder="Elegir tipo usuario"
                        options={tipoUsuarioOptions}
                        isSearchable={false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                  <ErrorMessage name="idTipoUsuario" component={ErrorFeedback} />

                  {/* NOTE: Campo requerido para Admin zona, admin y operador */}
                  {[3, 4, 6].includes(values.idTipoUsuario as number) && (
                    <>
                      <Field
                        name="idZona"
                        render={({ field }) => (
                          <SelectInput {...field} placeholder="Elegir zona" options={zonasOptions} setFieldValue={setFieldValue} />
                        )}
                      />
                      <ErrorMessage name="idZona" component={ErrorFeedback} />
                    </>
                  )}

                  <MDBBtn color="default" type="submit" className="btn-login mt-4" block disabled={isSubmitting}>
                    {isSubmitting ? <MDBIcon icon="spinner" pulse size="lg" /> : usuarioId ? "Actualizar" : "Guardar"}
                  </MDBBtn>
                </Form>
              )}
            />
          </MDBCardBody>
        </MDBCard>
      </div>
    </section>
  );
};

export default FormUsuario;

