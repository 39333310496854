import React from 'react';
import Select from 'react-select';
import { Option } from 'typings/General';

export interface SelectInputProps {
   name: string;
   placeholder: string;
   options: Option[];
   isClearable?: boolean;
   isSearchable?: boolean;
   isDisabled?: boolean;
   setFieldValue?: any;
   fetchRelatedFieldData?: any;
   doFetchRelatedField?: boolean;
   handleCustomSelect?: (inputName) => (option: any, { action }) => void;
   value: number | null;
}

const SelectInput: React.FC<SelectInputProps> = ({
   name,
   placeholder,
   options,
   isClearable,
   isSearchable,
   isDisabled,
   setFieldValue,
   fetchRelatedFieldData,
   doFetchRelatedField,
   handleCustomSelect,
   value
}) => {
   const customStyles = {
      container: (provided, state) => {
         return {
            ...provided,
            marginTop: '1rem'
         };
      },
      option: (provided, state) => ({
         ...provided
         // color: state.isSelected ? '#eb6c40' : null
         // backgroundColor: state.isSelected ? '#eb6c40' : null
      }),
      control: (provided, state) => {
         return {
            ...provided,
            '&:hover': {
               borderColor: '#eb6c40'
            },
            border: state.isFocused ? '1px solid #eb6c40' : '1px solid #dadce0',
            boxShadow: state.isFocused ? 'inset 0px 0px 0px 1px #eb6c40' : null
         };
      }
   };
   const noOptionsMessage = () => {
      return 'No hay resultados';
   };

   const handleChangeSelect = (option, { action }) => {
      setFieldValue(name, parseInt(option.value));
      if (name === 'idMarca' || (name === 'idZona' && doFetchRelatedField)) {
         fetchRelatedFieldData(option.value);
      }
   };

   return (
      <Select
         name={name}
         styles={customStyles}
         placeholder={placeholder}
         options={options}
         noOptionsMessage={noOptionsMessage}
         isClearable={isClearable}
         isSearchable={isSearchable}
         isDisabled={isDisabled}
         onChange={handleCustomSelect ? handleCustomSelect(name) : handleChangeSelect}
         {...(value !== null && !handleCustomSelect
            ? { defaultValue: options.find(option => option.value === value) }
            : value !== null && handleCustomSelect
            ? { value: options.find(option => option.value === value) }
            : { value: null })}
         // {...(options.length ? { defaultValue: options.find(option => option.value === value) } : {})}
         // {...(handleCustomSelect ? { value: options.find(option => option.value === value) } : {})}
      />
   );
};

export default SelectInput;
