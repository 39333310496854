import React from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdbreact';
import GruposLista from 'components/grupos/GruposLista';

export interface GruposPageProps {}

const GruposPage: React.FC<GruposPageProps> = () => {
   return (
      <section id='grupos'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar grupos</h3>
               </header>
            </MDBCol>
            <MDBCol className='text-right'>
               <Link to='/r/grupos/nuevo'>
                  <MDBBtn color='warning' className='text-capitalize btn-top-action'>
                     <MDBIcon className='mr-3' icon='plus' size='lg' />
                     Nuevo grupo
                  </MDBBtn>
               </Link>
            </MDBCol>
         </MDBRow>

         {/* TABLA DE GRUPOS */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <GruposLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default GruposPage;
