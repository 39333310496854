import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBCollapse } from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabContainer from "components/shared/TabContainer";
import Swal from "sweetalert2";
import { Map, TileLayer, Polygon } from "react-leaflet";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import ZonaMiembrosLista from "components/zonas/form/ZonaMiembrosLista";
import useScrollTop from "hooks/useScrollTop";
import http from "services/http.service";

const getTitle = (id: number) => {
  switch (id) {
    case 1:
      return "Tarifas para efectivo";
    case 2:
      return "Tarifas para tarjeta";
    case 3:
      return "Tarifas para monedero";
  }
};

export interface ZonaDetalleProps extends RouteComponentProps {}

const ZonaDetalles: React.FC<ZonaDetalleProps> = ({ match }) => {
  const zonaId = match.params["id"];
  const [collapseId, setCollapseId] = useState<string>("");
  const [zona, setZona] = useState<any>();

  // TABS
  const [tabValue, setTabValue] = useState<number>(0);

  // Mapa
  const mapa = useRef<any>(null);
  const [map, setMap] = useState<any>({
    polygonPoints: [],
  });

  // SmoothScroll
  useScrollTop();

  // Obtener informacion de la zona
  useEffect(() => {
    const fetchZona = async () => {
      try {
        const zonaData: any = await http.get(`zonas/${zonaId}`);
        setZona(zonaData);
        // Mappear puntos del poligono
        const polygonPoints = zonaData.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
        setMap({
          ...map,
          polygonPoints,
        });
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos de la zona, intente de nuevo.</ToastMessage>);
        }
      }
    };
    fetchZona();
    // eslint-disable-next-line
  }, [zonaId]);

  // Centrar la zona en el mapa
  useEffect(() => {
    if (map.polygonPoints.length) {
      mapa.current.leafletElement.fitBounds([map.polygonPoints]);
    }
  }, [map.polygonPoints]);

  const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ""));

  const handleToggleEstado = async () => {
    const result = await Swal.fire({
      title: `¿Estás seguro que deseas ${zona.activo ? "desactivar" : "activar"} la zona?`,
      text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      try {
        await http.put(`zonas/${zonaId}/${zona.activo ? "desactivar" : "activar"}`);
        setZona({
          ...zona,
          activo: !zona.activo,
        });
        toast.success(<ToastMessage type={"success"}>La acción fue realizada con éxito.</ToastMessage>);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
      }
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <section id="zonas">
      <MDBRow className="mb-4">
        <MDBCol className="align-self-center">
          <header>
            <h3 className="mb-0">
              <Link className="text-dark" to="/r/zonas">
                <MDBIcon className="mr-3" icon="arrow-left" />
              </Link>
              Detalles de zona de operación
            </h3>
          </header>
        </MDBCol>
        <MDBCol className="text-right">
          {zona && zona.activo && (
            <Link to={`/r/zonas/${zonaId}/editar`}>
              <MDBBtn color="indigo" className="text-capitalize btn-top-action">
                <MDBIcon className="mr-2" icon="edit" size="lg" />
                Editar
              </MDBBtn>
            </Link>
          )}
          <MDBBtn
            color={zona && zona.activo ? "success" : "danger"}
            className="text-capitalize btn-top-action"
            onClick={handleToggleEstado}>
            <MDBIcon className="mr-2" icon="power-off" size="lg" />
            {zona && zona.activo ? "Activo" : "Desactivado"}
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="8">
          {/* INFORMACION DE ZONA */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold">
              <MDBIcon size="lg" far icon="id-card" className="mr-3" />
              Información del zona
            </div>
            <MDBCardBody className="p-4">
              <MDBRow>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Nombre
                  </label>
                  <p>{zona ? zona.nombreZona : "N/A"}</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Super admin
                  </label>
                  <p>{zona ? `${zona.adminZona.nombres} ${zona.adminZona.primerApellido} ${zona.adminZona.segundoApellido}` : "N/A"}</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Correo de super admin
                  </label>
                  <p>{zona ? zona.adminZona.email : "N/A"}</p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Contraseña de super admin
                  </label>
                  <p>***********</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Télefono
                  </label>
                  <p>{zona ? zona.adminZona.telefono : "N/A"}</p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* USUARIOS */}
          <MDBCard className="mb-4">
            <div
              className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => handleToggleCard("usuarios")}>
              <span>
                <MDBIcon size="lg" icon="users" className="mr-3" />
                Usuarios
              </span>
              <span>
                <MDBIcon size="lg" icon={collapseId === "usuarios" ? "minus" : "plus"} />
              </span>
            </div>
            <MDBCollapse id="usuarios" isOpen={collapseId}>
              <MDBCardBody className="px-4 pb-4 pt-3">
                <Tabs
                  classes={{
                    indicator: "bg-dark",
                  }}
                  scrollButtons="auto"
                  onChange={handleTabChange}
                  value={tabValue}>
                  <Tab
                    classes={{
                      wrapper: "text-capitalize",
                    }}
                    label="Administradores"
                  />
                  <Tab
                    classes={{
                      wrapper: "text-capitalize",
                    }}
                    label="Operadores"
                  />
                </Tabs>

                {tabValue === 0 && (
                  <TabContainer>
                    <ZonaMiembrosLista idZona={zonaId} tipoMiembro={4} />
                  </TabContainer>
                )}
                {tabValue === 1 && (
                  <TabContainer>
                    <ZonaMiembrosLista idZona={zonaId} tipoMiembro={6} />
                  </TabContainer>
                )}
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* ZONA DE OPERACION */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center">
              <span>
                <MDBIcon size="lg" icon="map" className="mr-3" />
                Zona de operación
              </span>
              <span>
                <MDBIcon size="lg" icon="minus" />
              </span>
            </div>

            <MDBCardBody className="p-0">
              {/* MAPA */}
              <Map ref={mapa} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={map.polygonPoints} />
              </Map>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        {/* ESTADISTICAS */}
        <MDBCol md="4">
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white">
              <MDBIcon size="lg" icon="money-bill-wave" className="mr-3" />
              Pago
            </div>
            <MDBCardBody className="p-3">
              <p className="text-muted font-size-18 mt-2">Métodos de pago</p>

              <MDBRow>
                {zona && (
                  <Fragment>
                    <MDBCol className="font-weight-medium" md="9">
                      Tarjeta:
                    </MDBCol>
                    <MDBCol className="mb-3 text-right" md="3">
                      {zona.pagoTarjeta ? "Sí" : "No"}
                    </MDBCol>
                    <MDBCol className="font-weight-medium" md="9">
                      Efectivo:
                    </MDBCol>
                    <MDBCol className="mb-3 text-right" md="3">
                      {zona.pagoEfectivo ? "Sí" : "No"}
                    </MDBCol>
                  </Fragment>
                )}
              </MDBRow>

              {zona &&
                zona.tarifasZona.map(tarifa => (
                  <Fragment key={tarifa.idTarifa}>
                    <hr className="mt-0" />

                    <p className="text-muted font-size-18 mt-2">
                      {getTitle(tarifa.metodoPago.idMetodoPago as number)} - {tarifa.tipoVehiculo.nombre}
                    </p>
                    <MDBRow>
                      <MDBCol className="font-weight-medium" md="9">
                        Tarifa única:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.tarifaUnica ? `$${parseFloat(tarifa.tarifaUnica).toFixed(2)}` : "N/A"}
                      </MDBCol>
                      <MDBCol className="font-weight-medium" md="9">
                        Costo por kilómetro:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.costoKm ? `$${parseFloat(tarifa.costoKm).toFixed(2)}` : "N/A"}
                      </MDBCol>
                      <MDBCol className="font-weight-medium" md="9">
                        Costo por minuto:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.costoMin ? `$${parseFloat(tarifa.costoMin).toFixed(2)}` : "N/A"}
                      </MDBCol>
                      <MDBCol className="font-weight-medium" md="9">
                        Banderazo:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.banderazo ? `$${parseFloat(tarifa.banderazo).toFixed(2)}` : "N/A"}
                      </MDBCol>
                      <MDBCol className="font-weight-medium" md="9">
                        Tarifa mínima:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.tarifaMinima ? `$${parseFloat(tarifa.tarifaMinima).toFixed(2)}` : "N/A"}
                      </MDBCol>
                      <MDBCol className="font-weight-medium" md="9">
                        Multiplicador:
                      </MDBCol>
                      <MDBCol className="mb-3 text-right" md="3">
                        {tarifa.multiplicador ? `x${parseFloat(tarifa.multiplicador).toFixed(2)}` : "N/A"}
                      </MDBCol>
                    </MDBRow>
                  </Fragment>
                ))}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default ZonaDetalles;

