import React from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import ClientesLista from 'components/clientes/ClientesLista';

export interface ClientesPageProps {}

const ClientesPage: React.FC<ClientesPageProps> = () => {
   return (
      <section id='clientes'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar clientes</h3>
               </header>
            </MDBCol>
         </MDBRow>

         {/* TABLA DE CLIENTES */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <ClientesLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ClientesPage;
