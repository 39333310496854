import React, { Fragment, useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBBadge, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
// Mis Types
import { Pagination } from 'typings/Tablas';

interface Miembro {
   idUsuario: number;
   nombres: string;
   primerApellido: number;
   segundoApellido: number;
   email: number;
   telefono: number;
   activo: number;
   tipoUsuario: {
      idTipoUsuario: number;
      nombre: string;
   };
}

interface MiembrosMapped {
   idUsuario: number;
   nombres: string;
   telefono: number;
   estatus: number;
}

export interface ZonaMiembrosListProps {
   idZona: number;
   tipoMiembro: number;
}

const ZonaMiembrosList: React.FC<ZonaMiembrosListProps> = ({ idZona, tipoMiembro }) => {
   const [miembros, setMiembros] = useState<MiembrosMapped[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   const estatusBadgeFormatter = estatus => {
      if (estatus) {
         return <MDBBadge color='success px-4'>Activo</MDBBadge>;
      }
      return <MDBBadge color='danger'>Suspendido</MDBBadge>;
   };
   const columns = [
      {
         dataField: 'idUsuario',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombres',
         text: 'Nombre',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'telefono',
         text: 'Teléfono',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'estatus',
         text: 'Estado',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark',
         formatter: estatusBadgeFormatter
      }
   ];

   useEffect(() => {
      fetchMiembros();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page]);

   const fetchMiembros = async () => {
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(search ? { search } : {}),
            idZona,
            idTipoUsuario: tipoMiembro,
            limit,
            page
         };
         const { rows: miembrosList, count: totalSize }: any = await http.get('usuarios', { params });
         setMiembros(mapMiembrosToViewModel(miembrosList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         toast.error(<ToastMessage type={'error'}>Ocurrió un error al obtener los miembros, intente de nuevo.</ToastMessage>);
      }
   };

   const mapMiembrosToViewModel = (miembros: Miembro[]): MiembrosMapped[] => {
      return miembros.map(miembro => {
         return {
            idUsuario: miembro.idUsuario,
            nombres: `${miembro.nombres} ${miembro.primerApellido} ${miembro.segundoApellido}`,
            telefono: miembro.telefono,
            estatus: miembro.activo
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchMiembros();
   };

   const handleCleanFilters = () => {
      setSearch('');
   };

   const handleTableChange = (type, { page, sizePerPage }) => {
      setPagination({
         ...pagination,
         page
      });
   };

   return (
      <Fragment>
         <MDBRow className=''>
            <MDBCol md='8'>
               <MDBInput
                  className='m-0'
                  label='Buscar por nombre, teléfono, correo electrónico'
                  outline
                  icon='search'
                  iconSize='lg'
                  onChange={handleSearchChange}
                  value={search}
               />
            </MDBCol>
            <MDBCol md='4'>
               <div style={{ marginTop: '0.6rem' }}>
                  <Tooltip title='Actualizar' placement='top'>
                     <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                        <MDBIcon size='2x' icon='sync' fixed />
                     </MDBBtn>
                  </Tooltip>
                  <Tooltip title='Limpiar Filtros' placement='top'>
                     <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                        <MDBIcon size='2x' icon='eraser' fixed />
                     </MDBBtn>
                  </Tooltip>
               </div>
            </MDBCol>
         </MDBRow>
         <BootstrapTable
            bootstrap4
            condensed
            classes='miembros-table'
            keyField='idUsuario'
            data={miembros}
            columns={columns}
            remote={{
               pagination: true
            }}
            loading={isTableLoading}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            hover
            bordered={false}
            noDataIndication='No se encontraron registros.'
            rowClasses={'font-weight-bold'}
            pagination={paginationFactory({
               page: pagination.page, // Specify the current page.
               totalSize: pagination.totalSize, // Total data size.
               sizePerPage: pagination.sizePerPage, // Specify the size per page.
               withFirstAndLast: false, // hide the going to first and last page button
               alwaysShowAllBtns: true, // always show the next and previous page button
               prePageText: 'Anterior',
               nextPageText: 'Siguiente',
               hideSizePerPage: true, // hide the size per page dropdown
               hidePageListOnlyOnePage: true
            })}
            onTableChange={handleTableChange}
         />
      </Fragment>
   );
};

export default React.memo(ZonaMiembrosList);
