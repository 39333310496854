import React from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdbreact';
import UsuariosLista from 'components/usuarios/UsuariosLista';

export interface UsuariosPageProps {}

const UsuariosPage: React.FC<UsuariosPageProps> = () => {
   return (
      <section id='usuarios'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar usuarios</h3>
               </header>
            </MDBCol>
            <MDBCol className='text-right'>
               <Link to='/r/usuarios/nuevo'>
                  <MDBBtn color='warning' className='text-capitalize btn-top-action'>
                     <MDBIcon className='mr-3' icon='plus' size='lg' />
                     Nuevo usuario
                  </MDBBtn>
               </Link>
            </MDBCol>
         </MDBRow>

         {/* TABLA DE USUARIOS */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <UsuariosLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default UsuariosPage;
