import React from 'react';

class ErrorBoundary extends React.Component {
   state = { error: null, errorInfo: null };

   componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
         error: error,
         errorInfo: errorInfo
      });
      // You can also log error messages to an error reporting service here
   }

   render() {
      if (this.state.errorInfo) {
         // Error path
         return (
            <div className='p-5'>
               <h2 className='text-center'>Ocurrió un error al cargar el componente.</h2>
            </div>
         );
      }
      // Normally, just render children
      return this.props.children;
   }
}

export default ErrorBoundary;
