import React from 'react';
import { MDBIcon } from 'mdbreact';
import moment from 'moment';
import './PeriodPicker.css';

export interface PeriodPickerProps {
   onClickBtnAnterior: () => void;
   onClickBtnSiguiente: () => void;
   isBtnSiguienteDisabled?: boolean;
   values: string[] | string;
}

const PeriodPicker: React.SFC<PeriodPickerProps> = ({
   onClickBtnAnterior,
   onClickBtnSiguiente,
   isBtnSiguienteDisabled,
   values
}) => {
   return (
      <div className='sdr-period-picker'>
         <MDBIcon className='cursor-pointer p-3' icon='caret-left' size='lg' onClick={onClickBtnAnterior} />
         <span className='sdr-period-picker-dates d-inline-block text-center'>
            {moment(values[0]).format('DD [de] MMMM [del] YYYY')} - {moment(values[1]).format('DD [de] MMMM [del] YYYY')}
         </span>
         {!isBtnSiguienteDisabled && (
            <MDBIcon className='cursor-pointer p-3' icon='caret-right' size='lg' onClick={onClickBtnSiguiente} />
         )}
      </div>
   );
};

export default React.memo(PeriodPicker);
