import React, { memo, useCallback, useMemo } from 'react';
import { MDBIcon, MDBBtn } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './SaldosTabla.css';

export interface SaldosTablaProps {
   choferes: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   handlePagar: (id: number, index: number) => Promise<void>;
}

const SaldosTabla: React.SFC<SaldosTablaProps> = ({ choferes, isTableLoading, pagination, handleTableChange, handlePagar }) => {
   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <>
               <MDBBtn
                  color={row.activo ? 'success' : 'blue-grey'}
                  className='btn btn-sm my-0 mr-2 btn-action detalles-font-size text-capitalize'
                  onClick={() => handlePagar(row.idChofer, rowIndex)}
                  disabled={row.activo ? false : true}>
                  <MDBIcon className='mr-2' icon='dollar-sign' size='lg' />
                  Pagar
               </MDBBtn>
            </>
         );
      },
      [handlePagar]
   );

   const columns = useMemo(() => {
      return [
         {
            dataField: 'clave',
            text: 'Clave',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'nombreCompleto',
            text: 'Nombre',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'email',
            text: 'Monto a pagar',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'options',
            text: 'Opciones',
            // isDummyField: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter
         }
      ];
   }, [optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='saldos-table'
         keyField='idChofer'
         data={choferes}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(SaldosTabla);
