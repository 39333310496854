import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MDBIcon, MDBBadge, MDBBtn } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Componentes
import Tooltip from 'components/shared/Tooltip';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './ClientesTabla.css';

export interface ClientesTablaProps {
   clientes: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   handleToggleEstado: (id: number, activo: boolean, index: number) => Promise<void>;
}

const ClientesTabla: React.SFC<ClientesTablaProps> = ({
   clientes,
   isTableLoading,
   pagination,
   handleTableChange,
   handleToggleEstado
}) => {
   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <>
               <Tooltip title='Desactivar/Activar' placement='top'>
                  <MDBBtn
                     color={row.estado ? 'success' : 'danger'}
                     className='btn btn-sm my-0 mr-2 btn-action'
                     onClick={() => handleToggleEstado(row.idCliente, row.estado, rowIndex)}>
                     <MDBIcon size='2x' icon='power-off' />
                  </MDBBtn>
               </Tooltip>
               <Link
                  className='btn btn-primary btn-sm mr-2 detalles-font-size text-capitalize'
                  to={`/r/clientes/${row.idCliente}/detalles`}>
                  Ver más
               </Link>
            </>
         );
      },
      [handleToggleEstado]
   );

   const estatusBadgeFormatter = useCallback(estatus => {
      if (estatus) {
         return <MDBBadge color='success px-4'>Activo</MDBBadge>;
      }
      return <MDBBadge color='danger'>Inactivo</MDBBadge>;
   }, []);

   const columns = useMemo(() => {
      return [
         {
            dataField: 'idCliente',
            text: 'ID',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'nombre',
            text: 'Nombre',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'telefono',
            text: 'Teléfono',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'estado',
            text: 'Estado',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: estatusBadgeFormatter
         },
         {
            dataField: 'options',
            text: 'Opciones',
            isDummyField: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter
         }
      ];
   }, [estatusBadgeFormatter, optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='clientes-table'
         keyField='idCliente'
         data={clientes}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(ClientesTabla);
