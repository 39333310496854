import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import moment from 'moment';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
// import axios from 'axios';
import Swal from 'sweetalert2';
// Mis Componentes
import PeriodPicker from 'components/shared/PeriodPicker';
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import { getCurrentUser } from 'services/authentication.service';
import { hasPermissionTo } from 'services/authorization.service';
import SelectInput from 'components/shared/SelectInput';
import { mapOptionsToViewModel } from 'utils';
// Mis Types
import { Option } from 'typings/General';

const reportesOptions: Option[] = [
   { value: 'CHOFERES_ZONA', label: 'Reporte Choferes Taxistas' },
   { value: 'CORTE_ZONA', label: 'Reporte Cortes Taxistas' }
];
export interface ReportesProps {}

const Reportes: React.FC<ReportesProps> = () => {
   const user = getCurrentUser();
   const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
   const [reporteUrl, setReporteUrl] = useState<string>('');
   const [filters, setFilters] = useState<any>({
      startingDate: moment()
         .startOf('week')
         .subtract(1, 'week')
         .toISOString(),
      endingDate: moment()
         .endOf('week')
         .subtract(1, 'week')
         .toISOString(),
      zona: user.zona ? user.zona : process.env.REACT_APP_ZONA_DEFAULT ? parseInt(process.env.REACT_APP_ZONA_DEFAULT) : null,
      reporte: null
   });

   // Obtener los datos de los filtros
   useEffect(() => {
      const fetchFiltros = async () => {
         try {
            const params = {
               activo: true
            };
            const { rows: zonas }: any = await http.get('zonas', { params });
            setZonasOptions(mapOptionsToViewModel(zonas));
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
         }
      };

      fetchFiltros();
   }, []);

   useEffect(() => {
      setReporteUrl(
         `${process.env.REACT_APP_BASE_URL_ASSETS}/reportes/${filters.reporte}${filters.zona}_${moment(
            filters.startingDate
         ).week()}_${moment(filters.startingDate).year()}.xlsx`
      );
   }, [filters.startingDate, filters.zona, filters.reporte]);

   const handleBtnAnterior = () => {
      const beforeStartingDate = moment(filters.startingDate)
         .subtract(1, 'week')
         .toISOString();
      const beforeEndingDate = moment(filters.endingDate)
         .subtract(1, 'week')
         .toISOString();

      setFilters({ ...filters, startingDate: beforeStartingDate, endingDate: beforeEndingDate });
   };

   const handleBtnSiguiente = () => {
      const afterStartingDate = moment(filters.startingDate)
         .add(1, 'week')
         .toISOString();
      const afterEndingDate = moment(filters.endingDate)
         .add(1, 'week')
         .toISOString();

      setFilters({ ...filters, startingDate: afterStartingDate, endingDate: afterEndingDate });
   };

   const handleChangeSelect = inputName => (option, { action }) => {
      setFilters({
         ...filters,
         [inputName]: !option || option.value === null ? null : option.value.toString()
      });
   };

   const handleDownloadReporte = async () => {
      try {
         // const fileURL = `${process.env.REACT_APP_BASE_URL_ASSETS}/reportes/${filters.reporte}${filters.zona}_${moment(
         //    filters.startingDate
         // ).week()}_${moment(filters.startingDate).year()}.xlsx`;
         const response = await fetch(reporteUrl, {
            cache: 'no-store'
         });
         if (response.ok) {
            const file = await response.blob();
            saveAs(
               file,
               `${filters.reporte}${filters.zona}_${moment(filters.startingDate).week()}_${moment(
                  filters.startingDate
               ).year()}.xlsx`
            );
         } else if (response.status === 404) {
            Swal.fire('Atención!', 'No existen reportes para el período seleccionado.', 'warning');
            return;
         } else {
            Swal.fire('Error', 'Ocurrio un error al obtener el reporte.', 'error');
         }

         // let link = document.createElement('a');
         // link.href = reporteUrl;
         // link.download = `${filters.reporte}${filters.zona}_${moment(filters.startingDate).week()}_${moment(
         //    filters.startingDate
         // ).year()}.xlsx`;
         // link.click();
      } catch (error) {
         console.error(error);
         Swal.fire('Error de conexión', 'Verifica tu conexión a internet.', 'error');
      }
   };

   return (
      <>
         <div className=''>
            {/* NOTE: Se deshabilita el boton cuando esta entre Lunes y Domingo de la siguiente semana. */}
            <PeriodPicker
               onClickBtnAnterior={handleBtnAnterior}
               onClickBtnSiguiente={handleBtnSiguiente}
               isBtnSiguienteDisabled={moment().isBetween(
                  moment(filters.endingDate)
                     .add(1, 'day')
                     .startOf('day'),
                  moment(filters.endingDate).add(1, 'week')
               )}
               values={[filters.startingDate, filters.endingDate]}
            />
         </div>

         <MDBRow className=''>
            <MDBCol md='4'>
               <SelectInput
                  name='zona'
                  placeholder='Zona'
                  options={zonasOptions}
                  handleCustomSelect={handleChangeSelect}
                  isDisabled={hasPermissionTo('FILTER_BY_ZONA')}
                  value={filters.zona}
               />
            </MDBCol>
            <MDBCol md='4'>
               <SelectInput
                  name='reporte'
                  placeholder='Tipo de reporte'
                  options={reportesOptions}
                  handleCustomSelect={handleChangeSelect}
                  value={filters.reporte}
               />
            </MDBCol>
            <MDBCol md='4'>
               <div style={{ marginTop: '0.8rem' }}>
                  <MDBBtn
                     className='mb-0 px-5'
                     size='sm'
                     color='primary'
                     onClick={handleDownloadReporte}
                     disabled={!filters.reporte || !filters.zona}>
                     <MDBIcon className='mr-2' size='lg' icon='file-download' />
                     Descargar
                  </MDBBtn>
               </div>
            </MDBCol>
         </MDBRow>
      </>
   );
};

export default Reportes;
