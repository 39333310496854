import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBIcon, MDBMedia, MDBBadge } from "mdbreact";
import { Map, TileLayer, Polyline, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { toast } from "react-toastify";
// Mis componentes
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import SelectInput from "components/shared/SelectInput";
import ToastMessage from "components/shared/ToastMessage";
import http from "services/http.service";
import { hasPermissionTo } from "services/authorization.service";
import { mapOptionsToViewModel } from "utils";
import useInterval from "hooks/useInterval";
import ErrorBoundary from "components/shared/ErrorBoundary";
// Mis Types
import { ChoferOptions } from "models/Grupos";
import { Filters } from "typings/Tablas";
import { Option } from "typings/General";
// Assets
import "react-leaflet-markercluster/dist/styles.min.css";
import {
  inicioMarker,
  finalMarker,
  esperandoViajeTaxistaMarker,
  esperandoViajePrivadoMarker,
  esperandoViajePorViajesMarker,
  enCaminoTaxista,
  enCaminoPrivado,
  enCaminoPorViajes,
  esperandoClienteTaxista,
  esperandoClientePrivado,
  esperandoClientePorViajes,
  viajeIniciadoTaxista,
  viajeIniciadoPrivado,
  viajeIniciadoPorViajes,
  terminandoViajeTaxista,
  terminandoViajePrivado,
  terminandoViajePorViajes,
  taximetroTaxista,
  taximetroPrivado,
  taximetroPorViajes,
  castigadoTaxista,
  castigadoPrivado,
  castigadoPorViajes,
} from "utils/icons";
import esperandoViajeTaxistaIcon from "assets/images/markers/pin-success.png";
import esperandoViajePrivadoIcon from "assets/images/markers/pin-success-privado.png";
import esperandoViajeViajesIcon from "assets/images/markers/pin-success-viajes.png";
import enCaminoIcon from "assets/images/markers/pin-default.png";
import viajeIniciadoIcon from "assets/images/markers/pin-primary.png";
import esperandoClienteIcon from "assets/images/markers/pin-secondary.png";
import odometroIcon from "assets/images/markers/pin-light.png";
import castigadoIcon from "assets/images/markers/pin-warning.png";
import terminandoViajeIcon from "assets/images/markers/pin-danger.png";

const getIcon = vehiculo => {
  if (vehiculo.estadoOdometro) {
    switch (vehiculo.idTipoChofer) {
      case 1:
        return taximetroTaxista;
      case 2:
        return taximetroPrivado;
      case 3:
        return taximetroPorViajes;
      default:
        return taximetroTaxista;
    }
  }
  if (vehiculo.castigado) {
    switch (vehiculo.idTipoChofer) {
      case 1:
        return castigadoTaxista;
      case 2:
        return castigadoPrivado;
      case 3:
        return castigadoPorViajes;
      default:
        return castigadoTaxista;
    }
  }
  if (!vehiculo.idEstadoViaje) {
    // Esperando Viaje = vehiculo.idEstadoViaje === 7
    switch (vehiculo.idTipoChofer) {
      case 1:
        return esperandoViajeTaxistaMarker;
      case 2:
        return esperandoViajePrivadoMarker;
      case 3:
        return esperandoViajePorViajesMarker;
      default:
        return esperandoViajeTaxistaMarker;
    }
  }
  if (vehiculo.idEstadoViaje === 1) {
    // En camino
    switch (vehiculo.idTipoChofer) {
      case 1:
        return enCaminoTaxista;
      case 2:
        return enCaminoPrivado;
      case 3:
        return enCaminoPorViajes;
      default:
        return enCaminoTaxista;
    }
  }
  if (vehiculo.idEstadoViaje === 2) {
    // Chofer a punto de llegar
    switch (vehiculo.idTipoChofer) {
      case 1:
        return enCaminoTaxista;
      case 2:
        return enCaminoPrivado;
      case 3:
        return enCaminoPorViajes;
      default:
        return enCaminoTaxista;
    }
  }
  if (vehiculo.idEstadoViaje === 3) {
    // Esperando Cliente
    switch (vehiculo.idTipoChofer) {
      case 1:
        return esperandoClienteTaxista;
      case 2:
        return esperandoClientePrivado;
      case 3:
        return esperandoClientePorViajes;
      default:
        return esperandoClienteTaxista;
    }
  }
  if (vehiculo.idEstadoViaje === 4) {
    // En viaje
    switch (vehiculo.idTipoChofer) {
      case 1:
        return viajeIniciadoTaxista;
      case 2:
        return viajeIniciadoPrivado;
      case 3:
        return viajeIniciadoPorViajes;
      default:
        return viajeIniciadoTaxista;
    }
  }
  if (vehiculo.idEstadoViaje === 5) {
    // Terminando Viaje
    switch (vehiculo.idTipoChofer) {
      case 1:
        return terminandoViajeTaxista;
      case 2:
        return terminandoViajePrivado;
      case 3:
        return terminandoViajePorViajes;
      default:
        return terminandoViajeTaxista;
    }
  }
};

export interface MapaVehiculosProps {
  map: any;
  idZona: number;
  onZonaChange: (inputName: any) => (
    option: any,
    {
      action,
    }: {
      action: any;
    }
  ) => void;
}

const MapaVehiculos: React.FC<MapaVehiculosProps> = ({ map, idZona, onZonaChange }, ref) => {
  const [vehiculos, setVehiculos] = useState<any[]>([]);
  const [chofer, setChofer] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Selects
  const [choferes, setChoferes] = useState<ChoferOptions[]>([]);
  const [estatusOptions, setEstatusOptions] = useState<Option[]>([]);
  const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
  const [gruposOptions, setGruposOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    chofer: null,
    estatus: null,
    tipoChofer: null,
    grupo: null,
  });

  // Obtener los datos de los filtros
  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        const { rows: EstatusViaje }: any = await http.get("catalogos/EstatusViaje");
        EstatusViaje.unshift({
          idEstatusViaje: null,
          nombre: "Todos",
        });
        setEstatusOptions(mapOptionsToViewModel(EstatusViaje).filter((estatus: any) => estatus.value !== 6));

        const { rows: tiposChofer }: any = await http.get("catalogos/TiposChofer");
        tiposChofer.unshift({
          idTipoChofer: null,
          nombre: "Todos",
        });
        setTipoChoferOptions(mapOptionsToViewModel(tiposChofer));

        const params = {
          activo: true,
        };
        const { rows: zonas }: any = await http.get("zonas", { params });
        setZonasOptions(mapOptionsToViewModel(zonas));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
      }
    };

    fetchFiltros();
  }, []);

  // Obtener posicion de la zona y el filtro de grupo
  useEffect(() => {
    const fetchFiltroGrupos = async () => {
      try {
        const params = {
          activo: true,
          idZona,
        };
        const { rows: grupos }: any = await http.get("grupos", { params });
        if (grupos.length > 1)
          grupos.unshift({
            idGrupo: null,
            nombre: "Todos",
          });
        setGruposOptions(mapOptionsToViewModel(grupos));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
      }
    };

    const fetchZona = async () => {
      try {
        const zonaData: any = await http.get(`zonas/${idZona}`);
        // Mappear puntos del poligono
        const polygonPoints = zonaData.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
        ref.current.leafletElement.fitBounds([polygonPoints]);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener el area de la zona, intente de nuevo.</ToastMessage>);
      }
    };

    fetchZona();
    fetchFiltroGrupos();
    // eslint-disable-next-line
  }, [idZona]);

  // Refresh Map cada 30 seg
  useInterval(() => {
    fetchVehiculos();
  }, 30000);

  // Obtener los vehiculos del mapa
  useEffect(() => {
    fetchVehiculos();
    // eslint-disable-next-line
  }, [filters.chofer, filters.estatus, filters.tipoChofer, filters.grupo, idZona]);

  const fetchVehiculos = async () => {
    const { chofer, estatus, tipoChofer, grupo, zona } = filters;
    try {
      const params = {
        ...(chofer && { idChofer: chofer }),
        ...(estatus && { idEstadoViaje: estatus }),
        ...(tipoChofer && { idTipoChofer: tipoChofer }),
        ...(grupo && { idGrupo: grupo }),
        ...(zona && { idZona: zona }),
      };
      const vehiculosList: any[] = await http.get("monitor/choferes", { params });
      setVehiculos(vehiculosList);
    } catch (error) {
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los vehiculos.</ToastMessage>);
    }
  };

  const fetchChofer = async (id: number) => {
    try {
      const choferDetalles: any = await http.get(`choferes/${id}`);
      return choferDetalles;
    } catch (error) {
      if (error.type === "Chofer/ChoferNotFound") {
        toast.error(<ToastMessage type={"error"}>{error.message}</ToastMessage>);
        return;
      }
      toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos del chofer, intente de nuevo.</ToastMessage>);
    }
  };

  const searchChoferes = async (query: string) => {
    const { grupo, tipoChofer } = filters;

    try {
      setIsLoading(true);
      const params = {
        ...(query.trim() && { search: query }),
        ...(grupo && { idGrupo: grupo }),
        ...(tipoChofer && { idTipoChofer: tipoChofer }),
        ...(idZona && { idZona }),
        activo: true,
        limit: 10,
        page: 1,
      };
      const { rows: choferesList }: any = await http.get("choferes", { params });
      setChoferes(choferesList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if ((error.status && error.status !== 500) || error.type) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
      }
    }
  };

  const handleSelectedChofer = (selected: ChoferOptions[]) => {
    setFilters({
      ...filters,
      chofer: selected.length ? (selected[0].idChofer as number) : null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: !option || option.value === null ? null : option.value.toString(),
      });
    };

  const handleClickMarker = async (idChofer: number) => {
    const fetchedChofer = await fetchChofer(idChofer);
    if (fetchedChofer) setChofer(fetchedChofer);
  };

  const getPopupInfo = () => {
    if (!chofer) return null;

    return (
      <>
        <div>Información del chofer</div>
        <hr />

        <MDBMedia>
          <MDBMedia className="mr-4">
            <MDBMedia
              className="foto-chofer rounded-circle img-fluid"
              object
              src={process.env.REACT_APP_BASE_URL_ASSETS + chofer.imagen_url}
              alt="Foto del chofer"
            />
          </MDBMedia>
          <MDBMedia middle body>
            <p className="mt-0 mb-2 text-muted">{`${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}`}</p>
            {/* TODO: manejar el estatus */}
            <MDBBadge className="p-1" color="primary">
              Conectado
            </MDBBadge>
          </MDBMedia>
        </MDBMedia>

        <hr />

        <MDBRow>
          <MDBCol md="4">
            <p className="mt-0 mb-2">Vehiculo:</p>
          </MDBCol>
          <MDBCol className="text-right" md="8">
            <p className="mt-0 mb-2">{`${chofer.vehiculo[0].marca.nombre}, ${chofer.vehiculo[0].modelo.nombre}, ${chofer.vehiculo[0].vehicleYear}`}</p>
          </MDBCol>
          <MDBCol md="4">
            <p className="mt-0 mb-2">Placas:</p>
          </MDBCol>
          <MDBCol className="text-right" md="8">
            <p className="mt-0 mb-2">{chofer.vehiculo[0].placas.length > 0 ? chofer.vehiculo[0].placas[0].placas : "N/A"}</p>
          </MDBCol>
          <MDBCol md="4">
            <p className="mt-0 mb-2">Clave:</p>
          </MDBCol>
          <MDBCol className="text-right" md="8">
            <p className="mt-0 mb-2">{chofer.clave.length > 0 ? chofer.clave[0].clave : "N/A"}</p>
          </MDBCol>
          <MDBCol md="4">
            <p className="mt-0 mb-2">Celular:</p>
          </MDBCol>
          <MDBCol className="text-right" md="8">
            <p className="mt-0 mb-2">{chofer.telefono}</p>
          </MDBCol>
        </MDBRow>
        <div>
          <Link
            className="btn btn-primary btn-sm btn-block p-1 mt-2 detalles-font-size text-capitalize text-white"
            to={`/r/choferes/${chofer.idChofer}/detalles`}>
            Ver más
          </Link>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <h5 className="m-3">
        <MDBIcon icon="map-marked-alt text-kargo mr-3" />
        Mapa en vivo
      </h5>
      {/* FILTROS */}
      <p className="font-weight-medium ml-4 mb-0">Filtrar por</p>
      <MDBRow className="mb-3 mx-1">
        <MDBCol size="md">
          <div className="search-chofer position-relative">
            <AsyncTypeahead
              id={1}
              placeholder="Chofer"
              promptText="Escriba para buscar"
              emptyLabel="No se encontraron resultados"
              maxResults={10}
              minLength={3}
              delay={500}
              isLoading={isLoading}
              onSearch={searchChoferes}
              onChange={handleSelectedChofer}
              labelKey={(option: ChoferOptions) => `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`}
              clearButton={true}
              options={choferes}
            />
            <MDBIcon className="position-absolute" icon="search" size="lg" style={{ top: "10px", left: "9px" }} />
          </div>
        </MDBCol>
        <MDBCol size="md">
          <SelectInput
            name="tipoChofer"
            placeholder="Tipo Chofer"
            options={tipoChoferOptions}
            handleCustomSelect={handleChangeSelect}
            isClearable={true}
            value={filters.tipoChofer}
          />
        </MDBCol>
        <MDBCol size="md">
          <SelectInput
            name="estatus"
            placeholder="Estatus Viaje"
            options={estatusOptions}
            handleCustomSelect={handleChangeSelect}
            isClearable={true}
            value={filters.estatus}
          />
        </MDBCol>
        <MDBCol size="md">
          <SelectInput
            name="grupo"
            placeholder="Grupo"
            options={gruposOptions}
            handleCustomSelect={handleChangeSelect}
            isClearable={true}
            value={filters.grupo}
          />
        </MDBCol>
        <MDBCol size="md">
          <SelectInput
            name="zona"
            placeholder="Zona"
            options={zonasOptions}
            handleCustomSelect={onZonaChange}
            isDisabled={hasPermissionTo("FILTER_BY_ZONA")}
            value={idZona}
          />
        </MDBCol>
      </MDBRow>

      {/* MAPA */}
      <ErrorBoundary>
        <Map ref={ref} center={map.position} zoom={map.zoom} maxZoom={18} animate>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* RUTA DE VIAJE */}
          {!!map.polyline.length && (
            <>
              <Polyline positions={map.polyline} />
              <Marker position={map.initialPoint} title={"Inicio"} icon={inicioMarker} />
              <Marker position={map.finalPoint} title={"Final"} icon={finalMarker} />
            </>
          )}

          {vehiculos.length > 0 && (
            <MarkerClusterGroup>
              {vehiculos.map(vehiculo => {
                return (
                  <Marker
                    key={vehiculo.idChofer}
                    position={[vehiculo.position[0], vehiculo.position[1]]}
                    icon={getIcon(vehiculo)}
                    onClick={() => handleClickMarker(vehiculo.idChofer)}>
                    <Popup minWidth={253} closeButton={false}>
                      {getPopupInfo()}
                    </Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          )}
        </Map>
      </ErrorBoundary>

      {/* LEYENDA */}
      {/* <Fragment>
         <div className="text-center mt-3">
         <p className="font-weight-medium mb-0">Choferes conectados</p>
         </div>

        <MDBRow className="mt-3 mx-3 leyenda justify-content-center">
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={esperandoViajeTaxistaIcon} alt="Marcador Azul" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Taxista
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={esperandoViajePrivadoIcon} alt="Marcador Amarillo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Privado
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={esperandoViajeViajesIcon} alt="Marcador Rojo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Por viajes
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-3 mx-3 leyenda justify-content-center">
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={enCaminoIcon} alt="Marcador Azul" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                En camino
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={esperandoClienteIcon} alt="Marcador Amarillo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Esperando cliente
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={viajeIniciadoIcon} alt="Marcador Rojo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                En viaje
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={terminandoViajeIcon} alt="Marcador Rojo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Terminando viaje
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={odometroIcon} alt="Marcador Rojo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Taxímetro
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
          <MDBCol md="2">
            <MDBMedia>
              <MDBMedia left middle className="mr-2">
                <MDBMedia object src={castigadoIcon} alt="Marcador Rojo" />
              </MDBMedia>
              <MDBMedia body className="align-self-center">
                Castigado
              </MDBMedia>
            </MDBMedia>
          </MDBCol>
        </MDBRow>
      </Fragment> */}
    </Fragment>
  );
};

export default forwardRef(MapaVehiculos);

