import * as Sentry from '@sentry/browser';

function init() {
   Sentry.init({
      dsn: 'https://d75370c61dd24d878d9ce001f820a343@sentry.io/1872948'
   });
}

function log(error) {
   Sentry.captureException(error);
}

export default {
   init,
   log
};
