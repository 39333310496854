import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBNav, MDBNavItem } from 'mdbreact';
import { Bar } from 'react-chartjs-2';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import http from 'services/http.service';
import useScrollTop from 'hooks/useScrollTop';

export interface ViajeStatsGeneralesProps {}

const ViajeStatsGenerales: React.FC<ViajeStatsGeneralesProps> = () => {
   const [viajes, setViajes] = useState<any>();
   const [solicitudes, setSolicitudes] = useState<any>();
   const [ingresos, setIngresos] = useState<any>();
   const [tabValue, setTabValue] = useState<number>(0);
   const [pillValue, setPillValue] = useState<number>(1);
   const [barChart, setBarChart] = useState<any>({
      labels: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
      datasets: [
         {
            label: 'Horas',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4'],
            borderColor: ['#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4', '#0073A4']
         }
      ]
   });
   const barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         xAxes: [
            {
               barPercentage: 1,
               gridLines: {
                  display: false
               }
            }
         ],
         yAxes: [
            {
               gridLines: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)'
               },
               ticks: {
                  beginAtZero: true
               }
            }
         ]
      },
      legend: {
         display: false
      }
   };

   // SmoothScroll
   useScrollTop();

   // Obtener viajes, solicitudes o ingresos
   useEffect(() => {
      const fetchEstadisticas = async () => {
         try {
            const viajes: any = await http.get(`viajes/estadisticas`);
            setViajes(viajes);
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ha ocurrido un error al obtener los datos, intente de nuevo.</ToastMessage>);
         }
      };
      // fetchEstadisticas();
   }, [tabValue]);

   // Filtrar por semana, mes o año
   useEffect(() => {}, [pillValue]);

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setPillValue(1);
      setTabValue(newValue);
   };

   const handleClickPill = (value: number) => () => {
      setPillValue(value);
   };

   return (
      <section id='viajes'>
         <MDBRow className='mb-4'>
            <MDBCol className='align-self-center'>
               <header>
                  <h3 className='mb-0'>
                     <Link className='text-dark' to='/r/viajes'>
                        <MDBIcon className='mr-3' icon='arrow-left' />
                     </Link>
                     Estadísticas de viajes
                  </h3>
               </header>
            </MDBCol>
         </MDBRow>

         <MDBCard>
            <AppBar className='bg-primary' position='static'>
               <Tabs onChange={handleTabChange} value={tabValue}>
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Viajes'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Solicitudes'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Ingresos'
                  />
               </Tabs>
            </AppBar>
            <MDBCardBody className='px-4 pt-3 pb-4'>
               {tabValue === 0 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Mes
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Año
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICAS */}
                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Viajes realizados</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Viajes cancelados</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Viajes cancelados por clientes</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart mb-5' style={{ height: '18rem' }}>
                        <h5>Viajes cancelados por administrador</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>
                  </Fragment>
               )}
               {tabValue === 1 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Mes
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Año
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICAS */}
                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Realizadas por clientes</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Sin aceptar por choferes</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Rechazadas por choferes</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart mb-5' style={{ height: '18rem' }}>
                        <h5>Sin chofer disponible</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>
                  </Fragment>
               )}
               {tabValue === 2 && (
                  <Fragment>
                     <MDBNav className='md-pills nav-justified pills-deep-blue pills-rounded'>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 1 ? 'active' : ''}`} onClick={handleClickPill(1)}>
                              Semana
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 2 ? 'active' : ''}`} onClick={handleClickPill(2)}>
                              Mes
                           </a>
                        </MDBNavItem>
                        <MDBNavItem>
                           <a className={`nav-link ${pillValue === 3 ? 'active' : ''}`} onClick={handleClickPill(3)}>
                              Año
                           </a>
                        </MDBNavItem>
                     </MDBNav>

                     <hr />

                     <MDBRow className='my-4'>
                        <MDBCol>
                           <h5 className='mb-0'>05 de marzo de 2019 - 10 de marzo del 2019</h5>
                        </MDBCol>
                     </MDBRow>

                     <hr />

                     {/* GRAFICAS */}
                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Total de ingresos</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Ingresos en efectivo</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart' style={{ height: '18rem' }}>
                        <h5>Ingresos por tarjeta</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>

                     <hr className='mt-5 mb-4' />

                     <div className='bar-chart mb-5' style={{ height: '18rem' }}>
                        <h5>Odómetro</h5>
                        <Bar data={barChart} options={barChartOptions} />
                     </div>
                  </Fragment>
               )}
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ViajeStatsGenerales;
