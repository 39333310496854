import React, { useState, useEffect } from "react";
import { MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import { Formik, Field, Form, ErrorMessage, FormikActions, FormikProps } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import ErrorFeedback from "components/shared/ErrorFeedback";
import http from "services/http.service";
import apiErrorHandler from "services/apiErrorHandler.service";
// Mis Types
import { Config } from "models/Config";

export interface FormConfiguracionProps {}

const FormConfiguracion: React.FC<FormConfiguracionProps> = () => {
  // FORM STATE
  const [config, setConfig] = useState<Config>({
    costoKm: undefined,
    costoMin: undefined,
    banderazo: undefined,
    tarifaMinima: undefined,
    costoCargador: undefined,
    costoPiso: undefined,
    costoRemolque: undefined,
    limiteCargadores: undefined,
    limitePisos: undefined,
    limiteEscalas: undefined,
    tiempoEspera: undefined,
    distPuntoLlegar: undefined,
    distCancelarViaje: undefined,
  });

  const configSchema = Yup.object().shape({
    costoKm: Yup.number().typeError("Solo números").required("Campo requerido"),
    costoMin: Yup.number().typeError("Solo números").required("Campo requerido"),
    banderazo: Yup.number().typeError("Solo números").required("Campo requerido"),
    tarifaMinima: Yup.number().typeError("Solo números").required("Campo requerido"),
    costoCargador: Yup.number().typeError("Solo números").required("Campo requerido"),
    costoPiso: Yup.number().typeError("Solo números").required("Campo requerido"),
    costoRemolque: Yup.number().typeError("Solo números").required("Campo requerido"),
    limiteCargadores: Yup.number().typeError("Solo números").required("Campo requerido"),
    limitePisos: Yup.number().typeError("Solo números").required("Campo requerido"),
    limiteEscalas: Yup.number().typeError("Solo números").required("Campo requerido"),
    tiempoEspera: Yup.number().typeError("Solo números").required("Campo requerido"),
    distPuntoLlegar: Yup.number().typeError("Solo números").required("Campo requerido"),
    distCancelarViaje: Yup.number().typeError("Solo números").required("Campo requerido"),
  });

  // Obtener datos para editar usuario
  useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const configData: any = await http.get(`config`);
        populateConfig(configData);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener la configuración, intente de nuevo.</ToastMessage>);
      }
    };

    const populateConfig = config => {
      setConfig({
        costoKm: parseFloat(config.costoKm),
        costoMin: parseFloat(config.costoMin),
        banderazo: parseFloat(config.banderazo),
        tarifaMinima: parseFloat(config.tarifaMinima),
        costoCargador: parseFloat(config.costoCargador),
        costoPiso: parseFloat(config.costoPiso),
        costoRemolque: parseFloat(config.costoRemolque),
        limiteCargadores: parseFloat(config.limiteCargadores),
        limitePisos: parseFloat(config.limitePisos),
        limiteEscalas: parseFloat(config.limiteEscalas),
        tiempoEspera: config.tiempoEspera,
        distPuntoLlegar: parseFloat(config.distPuntoLlegar),
        distCancelarViaje: parseFloat(config.distCancelarViaje),
      });
    };

    fetchConfiguracion();
  }, []);

  const handleFormSubmit = async (values: Config, actions: FormikActions<Config>) => {
    try {
      const body = setBody(values);
      await http.put(`config`, body);

      await Swal.fire({
        title: "Configuración actualizada",
        text: "Los cambios han sido actualizados correctamente.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-info waves-effect waves-light text-capitalize",
        },
        buttonsStyling: false,
      });
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      apiErrorHandler("Config", error);
    }
  };

  const setBody = (configUpdate: Config) => {
    const body: object = {};
    for (const [key, value] of Object.entries(config)) {
      if (value !== configUpdate[key]) {
        body[key] = configUpdate[key];
      }
    }
    return body;
  };

  return (
    <section id="config">
      <div className="container">
        <MDBCard className="mx-auto" style={{ maxWidth: "40rem" }}>
          <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold">
            <MDBIcon size="lg" icon="cog" className="mr-3" />
            Modificar Variables Kargo
          </div>
          <MDBCardBody className="p-4">
            <Formik
              initialValues={config}
              enableReinitialize={true}
              validationSchema={configSchema}
              onSubmit={handleFormSubmit}
              render={({ isSubmitting }: FormikProps<Config>) => (
                <Form className="config-form">
                  <Field name="costoKm" render={({ field }) => <MDBInput outline label="Costo x Km" type="text" {...field} />} />
                  <ErrorMessage name="costoKm" component={ErrorFeedback} />

                  <Field name="costoMin" render={({ field }) => <MDBInput outline label="Costo x Minuto" type="text" {...field} />} />
                  <ErrorMessage name="costoMin" component={ErrorFeedback} />

                  <Field
                    name="banderazo"
                    render={({ field }) => <MDBInput outline label="Costo Inicial (Banderazo)" type="text" {...field} />}
                  />
                  <ErrorMessage name="banderazo" component={ErrorFeedback} />

                  <Field name="tarifaMinima" render={({ field }) => <MDBInput outline label="Tarifa Mínima" type="text" {...field} />} />
                  <ErrorMessage name="tarifaMinima" component={ErrorFeedback} />

                  <Field
                    name="costoCargador"
                    render={({ field }) => <MDBInput outline label="Costo x Cargador" type="text" {...field} />}
                  />
                  <ErrorMessage name="costoCargador" component={ErrorFeedback} />

                  <Field name="costoPiso" render={({ field }) => <MDBInput outline label="Costo x Piso" type="text" {...field} />} />
                  <ErrorMessage name="costoPiso" component={ErrorFeedback} />

                  <Field
                    name="costoRemolque"
                    render={({ field }) => <MDBInput outline label="Costo x Remolque" type="text" {...field} />}
                  />
                  <ErrorMessage name="costoRemolque" component={ErrorFeedback} />

                  <Field
                    name="limiteCargadores"
                    render={({ field }) => <MDBInput outline label="Limite Cargadores" type="text" {...field} />}
                  />
                  <ErrorMessage name="limiteCargadores" component={ErrorFeedback} />

                  <Field name="limitePisos" render={({ field }) => <MDBInput outline label="Limite Pisos" type="text" {...field} />} />
                  <ErrorMessage name="limitePisos" component={ErrorFeedback} />

                  <Field name="limiteEscalas" render={({ field }) => <MDBInput outline label="Limite Escalas" type="text" {...field} />} />
                  <ErrorMessage name="limiteEscalas" component={ErrorFeedback} />

                  <Field
                    name="tiempoEspera"
                    render={({ field }) => <MDBInput outline label="Tiempo de espera (en ms)" type="text" {...field} />}
                  />
                  <ErrorMessage name="tiempoEspera" component={ErrorFeedback} />

                  <Field
                    name="distPuntoLlegar"
                    render={({ field }) => <MDBInput outline label="Distancia punto de llegada (en metros)" type="text" {...field} />}
                  />
                  <ErrorMessage name="distPuntoLlegar" component={ErrorFeedback} />

                  <Field
                    name="distCancelarViaje"
                    render={({ field }) => (
                      <MDBInput outline label="Distancia cancelar peticion/viaje (en metros)" type="text" {...field} />
                    )}
                  />
                  <ErrorMessage name="distCancelarViaje" component={ErrorFeedback} />

                  <MDBBtn color="default" type="submit" className="btn-login mt-4" block disabled={isSubmitting}>
                    {isSubmitting ? <MDBIcon icon="spinner" pulse size="lg" /> : "Actualizar"}
                  </MDBBtn>
                </Form>
              )}
            />
          </MDBCardBody>
        </MDBCard>
      </div>
    </section>
  );
};

export default FormConfiguracion;

