import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
   MDBNavbar,
   MDBNavbarBrand,
   MDBNavbarNav,
   MDBDropdown,
   MDBDropdownMenu,
   MDBDropdownToggle,
   MDBDropdownItem,
   MDBNavItem,
   MDBIcon
} from 'mdbreact';
import { logout } from 'services/authentication.service';
import './Navbar.css';

export interface NavbarProps extends RouteComponentProps {
   onToggleClick: () => void;
   user: any;
}

const TopNavbar: React.FC<NavbarProps> = ({ onToggleClick, user, history }) => {
   return (
      <MDBNavbar className='flexible-navbar' color='white' light expand='md' scrolling>
         <MDBNavbarBrand onClick={onToggleClick} className='mr-0 p-0 cursor-pointer'>
            <MDBIcon icon='bars toggler-sidebar' size='lg' />
         </MDBNavbarBrand>
         <MDBNavbarNav right>
            <MDBNavItem>{/* <DropdownNotificaciones /> */}</MDBNavItem>
            <MDBNavItem>
               <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                     <div className='d-none d-md-inline pr-3'>
                        <MDBIcon icon='user-circle mr-2' size='lg' />
                        {`${user.nombre} ${user.primerApellido}`}
                     </div>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu right className='text-center'>
                     <MDBDropdownItem
                        onClick={() => {
                           logout();
                           history.replace('/login');
                        }}>
                        {' '}
                        <MDBIcon icon='fa-sign-out-alt mr-2' size='lg' />
                        Cerrar sesión
                     </MDBDropdownItem>
                  </MDBDropdownMenu>
               </MDBDropdown>
            </MDBNavItem>
         </MDBNavbarNav>
      </MDBNavbar>
   );
};

export default withRouter(TopNavbar);
