import React, { memo, useCallback, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MDBIcon, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './GruposTabla.css';

export interface GruposTablaProps extends RouteComponentProps {
   grupos: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
   handleToggleEstado: (id: number, index: number) => Promise<void>;
}

const GruposTabla: React.SFC<GruposTablaProps> = ({
   grupos,
   isTableLoading,
   pagination,
   handleTableChange,
   handleToggleEstado,
   history
}) => {
   const optionsFormatter = useCallback(
      (cell, row, rowIndex) => {
         return (
            <MDBDropdown>
               <MDBDropdownToggle color='primary' className='btn-sm my-0'>
                  <MDBIcon icon='ellipsis-h' size='2x' aria-haspopup='true' aria-expanded='false' />
               </MDBDropdownToggle>
               <MDBDropdownMenu right>
                  <MDBDropdownItem
                     onClick={() => {
                        history.push(`/r/grupos/${row.idGrupo}/editar`);
                     }}
                     disabled={row.activo ? false : true}>
                     <MDBIcon icon='edit' className='text-left mr-2' fixed />
                     Editar
                  </MDBDropdownItem>
                  <MDBDropdownItem onClick={() => handleToggleEstado(row.idGrupo, rowIndex)} disabled={row.activo ? false : true}>
                     <MDBIcon icon={row.activo ? 'times' : 'check'} className='text-left mr-2' fixed />
                     {row.activo ? 'Desactivar' : 'Inactivo'}
                  </MDBDropdownItem>
                  <MDBDropdownItem
                     className='text-center'
                     onClick={() => {
                        history.push(`/r/grupos/${row.idGrupo}/detalles`);
                     }}>
                     Ver más
                  </MDBDropdownItem>
               </MDBDropdownMenu>
            </MDBDropdown>
         );
      },
      //eslint-disable-next-line
      [handleToggleEstado]
   );

   const columns = useMemo(() => {
      return [
         {
            dataField: 'nombre',
            text: 'Nombre',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'choferes',
            text: 'Choferes',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'viajesRealizados',
            text: 'Viajes realizados',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'mayorTicket',
            text: 'Mayor ticket',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'recaudacion',
            text: 'Recaudación',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'cancelaciones',
            text: 'Cancelaciones',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark'
         },
         {
            dataField: 'options',
            text: 'Opciones',
            isDummyField: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'font-weight-bold text-dark',
            formatter: optionsFormatter
         }
      ];
   }, [optionsFormatter]);

   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='grupos-table'
         keyField='idGrupo'
         data={grupos}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default withRouter(memo(GruposTabla));
