import React from 'react';
import { Link } from 'react-router-dom';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdbreact';
import ChoferesLista from 'components/choferes/ChoferesLista';
import ChoferesStats from 'components/choferes/ChoferesStats';

export interface ChoferesPageProps {}

const ChoferesPage: React.FC<ChoferesPageProps> = () => {
   return (
      <section id='choferes'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Administrar choferes</h3>
               </header>
            </MDBCol>
            <MDBCol className='text-right'>
               <Link to='/r/choferes/nuevo'>
                  <MDBBtn color='warning' className='text-capitalize btn-top-action'>
                     <MDBIcon className='mr-3' icon='plus' size='lg' />
                     Nuevo chofer
                  </MDBBtn>
               </Link>
            </MDBCol>
         </MDBRow>

         {/* ESTADISTICAS */}
         <ChoferesStats />

         {/* TABLA DE CHOFERES */}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <ChoferesLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ChoferesPage;
