import React, { useState, useEffect, Fragment, useCallback } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import PeriodPicker from "components/shared/PeriodPicker";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
// Mis Types
import { Pagination } from "typings/Tablas";
import { Option } from "typings/General";
import GruposTabla from "./GruposTabla";

interface GrupoFilters {
  startingDate: string;
  endingDate: string;
  viajesRealizados: number | null;
  recaudacion: number | null;
  mayorTicket: number | null;
  cancelaciones: number | null;
}

const filtrosOptions: Option[] = [
  { value: "asc", label: "Menor" },
  { value: "desc", label: "Mayor" },
];

export interface GruposListaProps {}

const GruposLista: React.FC<GruposListaProps> = () => {
  const [grupos, setGrupos] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [filters, setFilters] = useState<GrupoFilters>({
    startingDate: moment().startOf("week").toISOString(),
    endingDate: moment().endOf("week").toISOString(),
    viajesRealizados: null,
    recaudacion: null,
    mayorTicket: null,
    cancelaciones: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  useEffect(() => {
    fetchGrupos();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.viajesRealizados, filters.recaudacion, filters.mayorTicket, filters.cancelaciones]);

  const fetchGrupos = async () => {
    const { viajesRealizados, recaudacion, mayorTicket, cancelaciones, startingDate, endingDate } = filters;
    const { page, sizePerPage: limit } = pagination;

    try {
      setIsTableLoading(true);

      // TODO: Falta agregar el filtro de fechas
      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(viajesRealizados && { viajesRealizados }),
        ...(recaudacion && { recaudacion }),
        ...(mayorTicket && { mayorTicket }),
        ...(cancelaciones && { cancelaciones }),
        limit,
        page,
      };
      const { rows: usuarios, count: totalSize }: any = await http.get("grupos", { params });
      setGrupos(mapGruposToViewModel(usuarios));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de grupos.</ToastMessage>);
    }
  };

  const mapGruposToViewModel = (grupos: any[]) => {
    return grupos.map(grupo => {
      return {
        idGrupo: grupo.idGrupo,
        nombre: grupo.nombre,
        choferes: grupo.totalChoferes ? grupo.totalChoferes : 0,
        viajesRealizados: grupo.totalViajes ? grupo.totalViajes : 0,
        mayorTicket: grupo.mayorTicket ? `$ ${parseFloat(grupo.mayorTicket).toFixed(2)}` : 0,
        recaudacion: grupo.recaudacion ? `$ ${parseFloat(grupo.recaudacion).toFixed(2)}` : 0,
        cancelaciones: grupo.cancelaciones ? grupo.cancelaciones : 0,
        activo: grupo.activo,
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchGrupos();
  };

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      ...filters,
      viajesRealizados: null,
      recaudacion: null,
      mayorTicket: null,
      cancelaciones: null,
    });
  };

  const handleBtnAnterior = () => {
    const beforeStartingDate = moment(filters.startingDate).subtract(1, "week").toISOString();
    const beforeEndingDate = moment(filters.endingDate).subtract(1, "week").toISOString();

    setFilters({ ...filters, startingDate: beforeStartingDate, endingDate: beforeEndingDate });
  };

  const handleBtnSiguiente = () => {
    const afterStartingDate = moment(filters.startingDate).add(1, "week").toISOString();
    const afterEndingDate = moment(filters.endingDate).add(1, "week").toISOString();

    setFilters({ ...filters, startingDate: afterStartingDate, endingDate: afterEndingDate });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value,
      });
    };

  const handleToggleEstado = useCallback(
    async (id: number, index: number) => {
      try {
        const result = await Swal.fire({
          title: `¿Estás seguro que deseas desactivar este grupo?`,
          text: "Ya no podras reactivar el grupo una vez desactivado.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`grupos/${id}/desactivar`);
          let gruposAux = [...grupos];

          // Actualizar UI
          gruposAux[index].activo = !gruposAux[index].activo;
          setGrupos(gruposAux);
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
      }
    },
    [grupos]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  return (
    <Fragment>
      <div className="table-filters py-2">
        {/* TODO: Logica para el filtro de fechas en la tabla */}
        <p className="font-weight-medium mt-3 mb-0">Ver ranking de la semana</p>
        <PeriodPicker
          onClickBtnAnterior={handleBtnAnterior}
          onClickBtnSiguiente={handleBtnSiguiente}
          isBtnSiguienteDisabled={moment().isBetween(moment(filters.startingDate), moment(filters.endingDate))}
          values={[filters.startingDate, filters.endingDate]}
        />

        <MDBRow className="">
          <MDBCol md="9">
            <MDBInput
              className="m-0"
              label="Buscar por nombre"
              outline
              icon="search"
              iconSize="lg"
              onChange={handleSearchChange}
              value={search}
            />
          </MDBCol>
          <MDBCol md="3">
            <div style={{ marginTop: "0.6rem" }}>
              <Tooltip title="Actualizar" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                  <MDBIcon size="2x" icon="sync" fixed />
                </MDBBtn>
              </Tooltip>
              <Tooltip title="Limpiar Filtros" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                  <MDBIcon size="2x" icon="eraser" fixed />
                </MDBBtn>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-3 mt-0 mx-0">
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="viajesRealizados"
              placeholder="Viajes realizados"
              options={filtrosOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.viajesRealizados}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="recaudacion"
              placeholder="Recaudacion"
              options={filtrosOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.recaudacion}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="mayorTicket"
              placeholder="Ticket"
              options={filtrosOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.mayorTicket}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="cancelaciones"
              placeholder="Cancelaciones"
              options={filtrosOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.cancelaciones}
            />
          </MDBCol>
        </MDBRow>
      </div>

      <GruposTabla
        grupos={grupos}
        isTableLoading={isTableLoading}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handleToggleEstado={handleToggleEstado}
      />
    </Fragment>
  );
};

export default GruposLista;

