import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import RegistrosLista from 'components/registros/RegistrosLista';

export interface RegistrosPageProps {}

const RegistrosPage: React.FC<RegistrosPageProps> = () => {
   return (
      <section id='registros'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Registros del sistema</h3>
               </header>
            </MDBCol>
         </MDBRow>

         {/* TABLA DE REGISTROS*/}
         <MDBCard>
            <MDBCardBody className='px-3 pb-3 pt-0'>
               <RegistrosLista />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default RegistrosPage;
