import React, { useState, useEffect, useRef } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBCollapse, MDBBadge } from "mdbreact";
import Swal from "sweetalert2";
import moment from "moment";
import { toast } from "react-toastify";
import Rating from "react-rating";
import polyline from "@mapbox/polyline";
import { Map, TileLayer, Polyline, Marker } from "react-leaflet";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import { inicioMarker, finalMarker } from "utils/icons";
import useScrollTop from "hooks/useScrollTop";
import http from "services/http.service";
import "./QuejaDetalles.css";
import ErrorBoundary from "components/shared/ErrorBoundary";

const getEstatusBadge = estatus => {
  switch (estatus) {
    case 1:
      return <MDBBadge color="danger px-4">Pendiente</MDBBadge>;
    case 2:
      return <MDBBadge color="warning px-4">En seguimiento</MDBBadge>;
    case 3:
      return <MDBBadge color="success px-4">Finalizado</MDBBadge>;
    default:
      return <span>N/A</span>;
  }
};

export interface QuejaDetallesProps extends RouteComponentProps {}

const QuejaDetalles: React.FC<QuejaDetallesProps> = ({ match }) => {
  const quejaId = match.params["id"];
  const [queja, setQueja] = useState<any>();
  const [collapseId, setCollapseId] = useState<string>("");
  const [comentarios, setComentarios] = useState<any[]>([]);

  // MAPA
  const mapa = useRef<any>(null);
  const polyLine = useRef<any>(null);
  const [map, setMap] = useState<any>({
    position: [29.0729673, -110.9559192],
    zoom: 12,
    polygonPoints: [],
    initialPoint: [],
    finalPoint: [],
  });

  // SmoothScroll
  useScrollTop();

  // Obtener los datos de una queja
  useEffect(() => {
    const fetchQueja = async () => {
      try {
        const quejaData: any = await http.get(`quejas/${quejaId}`);
        setQueja(quejaData);

        if (quejaData.viaje.polilineaViaje.polilinea) {
          // Decodificar polilinea y setear mapa
          const polygonPoints: number[][] = polyline.decode(quejaData.viaje.polilineaViaje.polilinea);
          polygonPoints.unshift([
            parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lat),
            parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lng),
          ]);
          polygonPoints.push([
            parseFloat(quejaData.viaje.polilineaViaje.dirFin.lat),
            parseFloat(quejaData.viaje.polilineaViaje.dirFin.lng),
          ]);

          setMap({
            ...map,
            polygonPoints,
            initialPoint: [
              parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lat),
              parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lng),
            ],
            finalPoint: [parseFloat(quejaData.viaje.polilineaViaje.dirFin.lat), parseFloat(quejaData.viaje.polilineaViaje.dirFin.lng)],
          });
        } else {
          setMap({
            ...map,
            initialPoint: [
              parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lat),
              parseFloat(quejaData.viaje.polilineaViaje.dirInicio.lng),
            ],
          });
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos, intente de nuevo.</ToastMessage>);
      }
    };

    const fetchComentarios = async () => {
      try {
        const { rows }: any = await http.get(`quejas/${quejaId}/comments`);
        setComentarios(mapComentariosToViewModel(rows));
        scrollChatBox();
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los comentarios, intente de nuevo.</ToastMessage>);
      }
    };

    fetchQueja();
    fetchComentarios();
    // eslint-disable-next-line
  }, [quejaId]);

  // Scroll chat al agregar comentarios
  useEffect(() => {
    scrollChatBox();
  }, [comentarios]);

  // Centrar la ruta del viaje en el mapa
  useEffect(() => {
    if (map.polygonPoints.length > 0) {
      if (map.initialPoint.length && map.finalPoint.length && polyLine.current) {
        const bounds = polyLine.current.leafletElement.getBounds();
        mapa.current.leafletElement.flyToBounds(bounds);
      }
    } else if (map.initialPoint.length > 0 && map.finalPoint.length === 0) {
      mapa.current.leafletElement.flyTo(map.initialPoint, 16);
    }
  }, [map]);

  const scrollChatBox = () => {
    const chatBox = document.getElementsByClassName("chat-box")[0];
    chatBox.scrollTop = chatBox.scrollHeight;
  };

  const mapComentarioToViewModel = comentario => {
    return {
      idComentario: comentario.idComentario,
      wiicab: `${comentario.wiicab.nombres} ${comentario.wiicab.primerApellido} ${comentario.wiicab.segundoApellido}`,
      mensaje: comentario.comentario,
      createdAt: comentario.createdAt,
    };
  };

  const mapComentariosToViewModel = comentarios => {
    return comentarios.map(comentario => {
      if (comentario.cliente) {
        return {
          idComentario: comentario.idComentario,
          cliente: comentario.cliente.nombre,
          mensaje: comentario.comentario,
          createdAt: comentario.createdAt,
        };
      }
      return {
        idComentario: comentario.idComentario,
        wiicab: `${comentario.wiicab.nombres} ${comentario.wiicab.primerApellido} ${comentario.wiicab.segundoApellido}`,
        mensaje: comentario.comentario,
        createdAt: comentario.createdAt,
      };
    });
  };

  const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ""));

  const handleEnviarComentario = async ({ currentTarget: input, key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === "Enter" && input.value) {
      try {
        const body = {
          comentario: input.value,
        };
        const savedComentario = await http.post(`quejas/${quejaId}/comentar`, body);
        setComentarios(comentarios.concat(mapComentarioToViewModel(savedComentario)));
        input.value = "";
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al enviar el mensaje, intente de nuevo.</ToastMessage>);
      }
    }
  };

  const handleReembolso = () => {
    // TODO: Abrir modal
  };

  const handleSeguimiento = async () => {
    const result = await Swal.fire({
      title: `¿Dar seguimiento?`,
      // text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      try {
        await http.put(`quejas/${quejaId}/seguimiento`);
        setQueja({
          ...queja,
          estadoQueja: {
            estadoQueja: 2,
            nombre: "En seguimiento",
          },
        });
        toast.success(<ToastMessage type={"success"}>La acción fue realizada con éxito.</ToastMessage>);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al marcar el seguimiento, intente de nuevo.</ToastMessage>);
      }
    }
  };

  const handleFinalizar = async () => {
    const result = await Swal.fire({
      title: `¿Estás seguro que deseas finalizar la queja?`,
      text: "No se puede revertir este cambio.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      try {
        await http.put(`quejas/${quejaId}/finalizar`);
        setQueja({
          ...queja,
          estadoQueja: {
            estadoQueja: 3,
            nombre: "Finalizado",
          },
        });
        toast.success(<ToastMessage type={"success"}>La acción fue realizada con éxito.</ToastMessage>);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al finalizar la queja, intente de nuevo.</ToastMessage>);
      }
    }
  };

  return (
    <section id="quejas">
      <MDBRow className="mb-4">
        <MDBCol className="align-self-center">
          <header>
            <h3 className="mb-0">
              <Link className="text-dark" to="/r/quejas">
                <MDBIcon className="mr-3" icon="arrow-left" />
              </Link>
              Detalles de la queja
            </h3>
          </header>
        </MDBCol>
        <MDBCol className="text-right">
          {/* TODO: funcionalidad pendiente */}
          <MDBBtn color="info" className="text-capitalize btn-top-action" onClick={handleReembolso}>
            <MDBIcon className="mr-2" icon="dollar-sign" size="lg" />
            Dar reembolso
          </MDBBtn>
          <MDBBtn
            color={queja && queja.castigado ? "warning" : "blue-grey"}
            className="text-capitalize btn-top-action"
            onClick={handleSeguimiento}
            disabled={queja && queja.estadoQueja.idEstatusQueja === 2}>
            <MDBIcon className="mr-2" icon="exclamation" size="lg" />
            {queja && queja.castigado ? "En seguimiento" : "En seguimiento"}
          </MDBBtn>
          <MDBBtn
            color={queja && queja.activo ? "success" : "danger"}
            className="text-capitalize btn-top-action"
            onClick={handleFinalizar}
            disabled={queja && queja.estadoQueja.idEstatusQueja === 3}>
            <MDBIcon className="mr-2" icon="check-double" size="lg" />
            {queja && queja.activo ? "Finalizar" : "Finalizado"}
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="8">
          {/* INFORMACION DE QUEJA */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold">
              <MDBIcon size="lg" far icon="id-card" className="mr-3" />
              Información del queja
            </div>
            <MDBCardBody className="p-4">
              <MDBRow>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="ID">
                    ID
                  </label>
                  <p>{queja ? queja.idQueja : "N/A"}</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="Fecha de creacion">
                    Fecha de creación
                  </label>
                  <p>{queja && queja.createdAt ? moment(queja.createdAt).format("L LT A") : "N/A"}</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="Estatus">
                    Estado
                  </label>
                  <p>{queja && queja.estadoQueja && getEstatusBadge(queja.estadoQueja.idEstatusQueja)}</p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* INFORMACION ADICIONAL */}
          <MDBCard className="mb-4">
            <div
              className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => handleToggleCard("informacion-adicional")}>
              <span>
                <MDBIcon size="lg" icon="route" className="mr-3" />
                Información de viaje
              </span>
              <span>
                <MDBIcon size="lg" icon={collapseId === "informacion-adicional" ? "minus" : "plus"} />
              </span>
            </div>
            <MDBCollapse id="informacion-adicional" isOpen={collapseId}>
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Punto de partida
                    </label>
                    <p>{queja ? queja.viaje.polilineaViaje.dirInicio.direccion : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Dirección de destino
                    </label>
                    <p>{queja ? queja.viaje.polilineaViaje.dirFin.direccion : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Método de pago
                    </label>
                    {/* TODO: pendiente de enviar por parte del backend */}
                    {/* <p>{queja ? queja.metodoPago : 'N/A'}</p> */}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Hora de solicitud de viaje
                    </label>
                    <p>{queja ? moment(queja.createdAt).format("L LT A") : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Hora de fin de viaje
                    </label>
                    <p>{queja ? moment(queja.viaje.dropTime).format("L LT A") : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Duración de viaje
                    </label>
                    <p>{queja ? queja.viaje.polilineaViaje.duracion : "N/A"}</p>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="4">
                    <label className="font-weight-medium" htmlFor="City">
                      Costo de viaje
                    </label>
                    <p>{queja ? queja.viaje.costo : "N/A"}</p>
                  </MDBCol>
                </MDBRow>

                <hr />

                <p className="font-size-18 text-muted mt-3">Información de cliente</p>
                <MDBRow className="mt-3">
                  <MDBCol md="4">
                    <label className="font-weight-medium">ID del cliente</label>
                    <p>{queja ? queja.viaje.cliente.idCliente : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Nombre de cliente</label>
                    <p>{queja ? queja.viaje.cliente.nombre : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Calificación de cliente</label>
                    <p>
                      {queja ? (
                        <Rating
                          initialRating={queja.viaje.cliente.calificacion ? queja.viaje.cliente.calificacion : 5}
                          readonly
                          emptySymbol="far fa-star fa-lg mr-1"
                          fullSymbol="fas fa-star fa-lg mr-1"
                        />
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Calificación de viaje</label>
                    <p>
                      {queja ? (
                        <Rating
                          initialRating={queja.viaje.puntosCliente ? queja.viaje.puntosCliente.rate : 0}
                          readonly
                          emptySymbol="far fa-star fa-lg mr-1"
                          fullSymbol="fas fa-star fa-lg mr-1"
                        />
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </MDBCol>
                </MDBRow>

                <hr />

                <p className="font-size-18 text-muted mt-3">Información de chofer</p>
                <MDBRow className="mt-3">
                  <MDBCol md="4">
                    <label className="font-weight-medium">ID de chofer</label>
                    <p>{queja ? queja.viaje.chofer.idChofer : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Nombre de chofer</label>
                    <p>
                      {queja
                        ? `${queja.viaje.chofer.nombres} ${queja.viaje.chofer.primerApellido} ${queja.viaje.chofer.segundoApellido}`
                        : "N/A"}
                    </p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Calificación de chofer</label>
                    <p>
                      {queja ? (
                        <Rating
                          initialRating={queja.viaje.puntosChofer ? queja.viaje.puntosChofer.rate : 0}
                          readonly
                          emptySymbol="far fa-star fa-lg mr-1"
                          fullSymbol="fas fa-star fa-lg mr-1"
                        />
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4">
                    <label className="font-weight-medium">No. de unidad</label>
                    <p>{queja ? queja.viaje.vehiculoChofer.numeroVehiculo : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Modelo</label>
                    <p>{queja ? queja.viaje.vehiculoChofer.modelo.nombre : "N/A"}</p>
                  </MDBCol>
                  <MDBCol md="4">
                    <label className="font-weight-medium">Placas</label>
                    <p>{queja ? queja.viaje.placasVehiculo.placas : "N/A"}</p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* MAPA DE RECORRIDO */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold d-flex justify-content-between align-items-center">
              <span>
                <MDBIcon size="lg" icon="map" className="mr-3" />
                Mapa de recorrido
              </span>
              <span>
                <MDBIcon size="lg" icon="minus" />
              </span>
            </div>

            <MDBCardBody className="p-0">
              <ErrorBoundary>
                {/* MAPA */}
                <Map ref={mapa} center={map.position} zoom={map.zoom} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Polyline ref={polyLine} positions={map.polygonPoints} />

                  {/* Mostrar solo punto inicial cuando el punto inicial y el final son iguales */}
                  {map.initialPoint.length > 0 && map.finalPoint.length === 0 && (
                    <>
                      <Marker position={map.initialPoint} title={"Inicio"} icon={inicioMarker} />
                    </>
                  )}

                  {/* Mostrar punto inicial y final cuando hay polilinea y los puntos inicial y final son diferentes */}
                  {map.initialPoint.length > 0 && map.finalPoint.length > 0 && (
                    <>
                      <Marker position={map.initialPoint} title={"Inicio"} icon={inicioMarker} />
                      <Marker position={map.finalPoint} title={"Final"} icon={finalMarker} />
                    </>
                  )}
                </Map>
              </ErrorBoundary>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        {/* MESSENGER */}
        <MDBCol md="4">
          <MDBCard>
            <div className="card-header card-header-shadow bg-dark-orange text-white">
              <MDBIcon size="lg" icon="comments" className="mr-3" />
              Comentarios
            </div>
            <MDBCardBody className="chat-box bg-light px-4 py-4">
              <div className="d-flex flex-column">
                {/* COMENTARIOS */}
                {comentarios.map(comentario => (
                  <div
                    key={comentario.idComentario}
                    className={`mensaje-box bg-white p-3 mb-4 ${comentario.wiicab ? "align-self-end" : ""}`}>
                    <div className="mensaje-metadata d-flex justify-content-between mb-3">
                      <span className="font-weight-medium">{comentario.cliente ? comentario.cliente : comentario.wiicab}</span>
                      <small>{moment(comentario.createdAt).format("L LT A")}</small>
                    </div>
                    {comentario.mensaje}
                  </div>
                ))}
              </div>
            </MDBCardBody>
            <div className="card-footer text-muted p-0">
              <input type="text" className="form-control send-msg" placeholder="Escribe un mensaje" onKeyDown={handleEnviarComentario} />
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default QuejaDetalles;

