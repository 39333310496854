import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import Switch from "@material-ui/core/Switch";
// Mis Componentes
import SelectInput from "components/shared/SelectInput";
import ErrorFeedback from "components/shared/ErrorFeedback";
// Mis Types
import { Chofer } from "models/Choferes";
import { Option } from "typings/General";

interface AsignarVehiculoProps {
  chofer: Chofer;
  marcas: Option[];
  modelos: Option[];
  anos: Option[];
  tipos: Option[];
  fetchModelosVehiculos: (id: number) => Promise<void>;
  onBackBtnClick: () => void;
  onStepSubmit: (values, actions) => void;
}

const AsignarVehiculo: React.FC<AsignarVehiculoProps> = ({
  chofer,
  marcas,
  modelos,
  anos,
  tipos,
  fetchModelosVehiculos,
  onBackBtnClick,
  onStepSubmit,
}) => {
  console.log(chofer);

  const initialValues = {
    numeroVehiculo: chofer.numeroVehiculo,
    placas: chofer.placas,
    idMarca: chofer.idMarca,
    idModelo: chofer.idModelo,
    vehicleYear: chofer.vehicleYear,
    color: chofer.color,
    idTipoVehiculo: chofer.idTipoVehiculo,
    remolque: chofer.remolque,
    numCargadores: chofer.numCargadores,
    numPisos: chofer.numPisos,
  };

  const asignarVehiculoSchema = Yup.object().shape({
    numeroVehiculo: Yup.string().required("Campo requerido"),
    placas: Yup.string().required("Campo requerido"),
    idMarca: Yup.number().nullable().required("Campo requerido"),
    idModelo: Yup.number().nullable().required("Campo requerido"),
    vehicleYear: Yup.number().nullable().required("Campo requerido"),
    idTipoVehiculo: Yup.number().nullable().required("Campo requerido"),
    color: Yup.string().required("Campo requerido"),
    remolque: Yup.boolean().required("Campo requerido"),
    numCargadores: Yup.number().nullable().required("Campo requerido"),
    numPisos: Yup.number().nullable().required("Campo requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={asignarVehiculoSchema}
      onSubmit={onStepSubmit}
      render={({ setFieldValue }: FormikProps<any>) => (
        <Form>
          <p className="text-muted font-size-18">Información de vehículo</p>

          <MDBRow className="mb-4">
            <MDBCol>
              <Field name="numeroVehiculo" render={({ field }) => <MDBInput {...field} outline label="Número de unidad" type="text" />} />
              <ErrorMessage name="numeroVehiculo" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field name="placas" render={({ field }) => <MDBInput {...field} outline label="Placas" type="text" />} />
              <ErrorMessage name="placas" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field
                name="idTipoVehiculo"
                render={({ field }) => (
                  <SelectInput {...field} placeholder="Tipo de vehículo" options={tipos} setFieldValue={setFieldValue} />
                )}
              />
              <ErrorMessage name="idTipoVehiculo" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-4">
            <MDBCol>
              <Field
                name="idMarca"
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    placeholder="Marca de vehículo"
                    options={marcas}
                    setFieldValue={setFieldValue}
                    fetchRelatedFieldData={fetchModelosVehiculos}
                  />
                )}
              />
              <ErrorMessage name="idMarca" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field
                name="idModelo"
                render={({ field }) => (
                  <SelectInput {...field} placeholder="Modelo del vehículo" options={modelos} setFieldValue={setFieldValue} />
                )}
              />
              <ErrorMessage name="idModelo" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field
                name="vehicleYear"
                render={({ field }) => (
                  <SelectInput {...field} placeholder="Año del vehículo" options={anos} setFieldValue={setFieldValue} />
                )}
              />
              <ErrorMessage name="vehicleYear" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4">
            <MDBCol md="4">
              <Field name="numCargadores" render={({ field }) => <MDBInput {...field} outline label="No. de cargadores" type="number" />} />
              <ErrorMessage name="numCargadores" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="numPisos" render={({ field }) => <MDBInput {...field} outline label="No. de pisos" type="number" />} />
              <ErrorMessage name="numPisos" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="color" render={({ field }) => <MDBInput {...field} outline label="Color de vehículo" type="color" />} />
              <ErrorMessage name="color" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4">
            <MDBCol md="4">
              <Field
                name="remolque"
                render={({ field }) => (
                  <p className="mb-0 mt-2">
                    ¿Remolque?
                    <span className="font-weight-medium ml-4">
                      No
                      <Switch color="primary" {...field} defaultChecked={chofer.remolque} />
                      Sí
                    </span>
                  </p>
                )}
              />
              <ErrorMessage name="remolque" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-5 justify-content-center">
            <MDBCol md="4">
              <MDBBtn outline className="font-weight-medium mx-0 px-5 text-capitalize" block onClick={onBackBtnClick}>
                Atrás
              </MDBBtn>
            </MDBCol>
            <MDBCol md="4">
              <MDBBtn color="default" type="submit" className="font-weight-medium mx-0 px-5" block>
                Siguiente
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </Form>
      )}
    />
  );
};

export default AsignarVehiculo;

