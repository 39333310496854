import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { MDBBadge } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './ViajesTabla.css';

const columns = [
   {
      dataField: 'idViaje',
      text: 'ID',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'estatus',
      text: 'Estatus',
      isDummyField: true,
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: estatusBadgeFormatter
   },
   {
      dataField: 'nombreChofer',
      text: 'Chofer',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'nombreCliente',
      text: 'Cliente',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'monto',
      text: 'Monto',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'options',
      text: 'Opciones',
      isDummyField: true,
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: optionsFormatter
   }
];

function optionsFormatter(cell, row, rowIndex) {
   return (
      <Link className='btn btn-primary btn-sm mr-2 detalles-font-size text-capitalize' to={`/r/viajes/${row.idViaje}/detalles`}>
         Ver más
      </Link>
   );
}

function estatusBadgeFormatter(cell, row) {
   if (row.estatus.cancelado) {
      return <MDBBadge color='danger'>Cancelado</MDBBadge>;
   }
   switch (row.estatus.idEstatusViaje) {
      case 1:
         return <MDBBadge color='info'>{row.estatus.nombre}</MDBBadge>;
      case 2:
         return <MDBBadge color='primary'>{row.estatus.nombre}</MDBBadge>;
      case 3:
         return <MDBBadge color='secondary'>{row.estatus.nombre}</MDBBadge>;
      case 4:
         return <MDBBadge color='success'>{row.estatus.nombre}</MDBBadge>;
      case 5:
         return <MDBBadge color='warning'>{row.estatus.nombre}</MDBBadge>;
      case 6:
         return <MDBBadge color='light'>{row.estatus.nombre}</MDBBadge>;
      case 7:
         return <MDBBadge color='default'>{row.estatus.nombre}</MDBBadge>;
   }
}

export interface ViajesTablaProps {
   viajes: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
}

const ViajesTabla: React.SFC<ViajesTablaProps> = ({ viajes, isTableLoading, pagination, handleTableChange }) => {
   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='viajes-table'
         keyField='idViaje'
         data={viajes}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(ViajesTabla);
