import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from 'mdbreact';
import { toast } from 'react-toastify';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import RegistrosTabla from './RegistrosTabla';
import Tooltip from 'components/shared/Tooltip';
import http from 'services/http.service';
import useDebounce from 'hooks/useDebounce';
// Mis Types
import { Pagination } from 'typings/Tablas';

export interface RegistrosListaProps {}

const RegistrosLista: React.FC<RegistrosListaProps> = () => {
   const [registros, setRegistros] = useState<any[]>([]);
   const [search, setSearch] = useState<string>('');
   const debouncedSearch = useDebounce(search.trim(), 500);
   const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
   const [pagination, setPagination] = useState<Pagination>({
      page: 1,
      totalSize: 10,
      sizePerPage: 10
   });

   useEffect(() => {
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      // fetchRegistros();
      // eslint-disable-next-line
   }, [debouncedSearch, pagination.page]);

   const fetchRegistros = async () => {
      const { page, sizePerPage: limit } = pagination;
      try {
         setIsTableLoading(true);

         const params = {
            ...(debouncedSearch && { search: debouncedSearch }),
            limit,
            page
         };
         const { rows: registrosList, count: totalSize }: any = await http.get('registros', { params });
         setRegistros(mapRegistrosToViewModel(registrosList));
         setPagination({ ...pagination, totalSize });

         setIsTableLoading(false);
      } catch (error) {
         setIsTableLoading(false);
         toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar la lista de registros.</ToastMessage>);
      }
   };

   const mapRegistrosToViewModel = (registros: any[]) => {
      return registros.map(registro => {
         return {
            idRegistro: registro.idregistro,
            fechaRegistro: registro.fechaRegistro,
            generadoPor: registro.generadoPor,
            descripcion: registro.descripcion
         };
      });
   };

   const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(input.value);
   };

   const handleRefresh = () => {
      fetchRegistros();
   };

   const handleCleanFilters = () => {
      setSearch('');
   };

   const handleTableChange = useCallback(
      (type, { page, sizePerPage }) => {
         setPagination({
            ...pagination,
            page
         });
      },
      [pagination]
   );

   return (
      <Fragment>
         <MDBRow className='table-filters py-2 mb-2'>
            <MDBCol md='9'>
               <MDBInput
                  className='m-0'
                  label='Buscar generado por'
                  outline
                  icon='search'
                  iconSize='lg'
                  onChange={handleSearchChange}
                  value={search}
               />
            </MDBCol>
            <MDBCol md='3'>
               <div style={{ marginTop: '0.6rem' }}>
                  <Tooltip title='Actualizar' placement='top'>
                     <MDBBtn size='sm' color='danger' onClick={handleRefresh}>
                        <MDBIcon size='2x' icon='sync' fixed />
                     </MDBBtn>
                  </Tooltip>
                  <Tooltip title='Limpiar Filtros' placement='top'>
                     <MDBBtn size='sm' color='danger' onClick={handleCleanFilters}>
                        <MDBIcon size='2x' icon='eraser' fixed />
                     </MDBBtn>
                  </Tooltip>
               </div>
            </MDBCol>
         </MDBRow>

         <RegistrosTabla
            registros={registros}
            isTableLoading={isTableLoading}
            pagination={pagination}
            handleTableChange={handleTableChange}
         />
      </Fragment>
   );
};

export default RegistrosLista;
