import Leaflet from "leaflet";

export const inicioMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/start.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const escalaMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/escala.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const finalMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/final.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoViajeTaxistaMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-success.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoViajePrivadoMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-success-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoViajePorViajesMarker = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-success-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const enCaminoTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-default.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const enCaminoPrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-default-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const enCaminoPorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-default-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoClienteTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-secondary.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoClientePrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-secondary-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const esperandoClientePorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-secondary-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const viajeIniciadoTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-primary.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const viajeIniciadoPrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-primary-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const viajeIniciadoPorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-primary-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const terminandoViajeTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-danger.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const terminandoViajePrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-danger-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const terminandoViajePorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-danger-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const taximetroTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-light.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const taximetroPrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-light-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const taximetroPorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-light-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const castigadoTaxista = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-warning.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const castigadoPrivado = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-warning-privado.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

export const castigadoPorViajes = Leaflet.icon({
  iconUrl: require("../assets/images/markers/pin-warning-viajes.png"),
  iconSize: [30.1, 40.1],
  iconAnchor: [15, 40],
});

