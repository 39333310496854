import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'services/authentication.service';

const PrivateRoute = ({ component: Component, ...rest }) => (
   <Route
      {...rest}
      render={props => {
         const token = getToken();
         if (token) {
            return <Component {...props} />;
         }
         return <Redirect to='/login' />;
      }}
   />
);

export default PrivateRoute;
