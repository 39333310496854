import React, { Fragment, forwardRef } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { Map, TileLayer, Polygon } from 'react-leaflet';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Option } from 'typings/General';
import { Grupo } from 'models/Grupos';

export interface GrupoResumenProps {
   grupo: Grupo;
   map: any;
   miembros: any[];
   choferesLideres: Option[];
   zonas: Option[];
   onBackBtnClick: () => void;
   onFormSubmit: () => void;
}

const GrupoResumen: React.FC<GrupoResumenProps> = (
   { grupo, map, miembros, choferesLideres, zonas, onBackBtnClick, onFormSubmit },
   ref
) => {
   const columns = [
      {
         dataField: 'idChofer',
         text: 'ID',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'nombre',
         text: 'Nombre',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'telefono',
         text: 'Teléfono',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      },
      {
         dataField: 'email',
         text: 'Correo electrónico',
         headerAlign: 'center',
         align: 'center',
         headerClasses: 'font-weight-bold text-dark'
      }
   ];

   const getLiderName = (id: number) => {
      const lider: Option = choferesLideres.find(lider => lider.value === id) as Option;
      return lider.label;
   };

   const getZonaName = (id: number) => {
      const zona: Option = zonas.find(zona => zona.value === id) as Option;
      return zona.label;
   };

   return (
      <Fragment>
         <p className='text-muted font-size-18'>Información de grupo</p>

         <MDBRow className='mb-4'>
            <MDBCol md='3'>
               <label className=''>Nombre de grupo</label>
               <p className='font-weight-medium'>{grupo.nombre}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label className=''>Lider de grupo</label>
               <p className='font-weight-medium'>{grupo.idChoferLider ? getLiderName(grupo.idChoferLider) : 'N/A'}</p>
            </MDBCol>
            <MDBCol md='3'>
               <label className=''>Zona de operación</label>
               <p className='font-weight-medium'>{grupo.idZona ? getZonaName(grupo.idZona) : 'N/A'}</p>
            </MDBCol>
         </MDBRow>

         {/* MAPA */}
         <Map ref={ref} boxZoom={false} doubleClickZoom={false} scrollWheelZoom={false}>
            <TileLayer
               attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Polygon positions={map.polygonPoints} />
         </Map>

         <hr className='mt-4' />
         <p className='text-muted mt-4 font-size-18'>Choferes en grupo</p>

         <BootstrapTable
            bootstrap4
            condensed
            classes='grupo-table'
            keyField='idChofer'
            data={miembros}
            columns={columns}
            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
            hover
            bordered={false}
            noDataIndication='No se agregaron choferes.'
            rowClasses={'font-weight-bold'}
         />

         <MDBRow className='mt-5 justify-content-center'>
            <MDBCol md='4'>
               <MDBBtn outline className='font-weight-medium mx-0 px-5 text-capitalize' block onClick={onBackBtnClick}>
                  Atrás
               </MDBBtn>
            </MDBCol>
            <MDBCol md='4'>
               <MDBBtn color='default' className='font-weight-medium mx-0 px-5' block onClick={onFormSubmit}>
                  Terminar
               </MDBBtn>
            </MDBCol>
         </MDBRow>
      </Fragment>
   );
};

export default forwardRef(GrupoResumen);
