import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { MDBBadge } from 'mdbreact';
import Rating from 'react-rating';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
// Mis Types
import { Pagination } from 'typings/Tablas';
import './QuejasTabla.css';

const columns = [
   {
      dataField: 'idQueja',
      text: 'ID',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark'
   },
   {
      dataField: 'calChofer',
      text: 'Calificación chofer',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: ratingFormatter
   },
   {
      dataField: 'calUsuario',
      text: 'Calificación cliente',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: ratingFormatter
   },
   {
      dataField: 'estatus',
      text: 'Estado',
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: estatusBadgeFormatter
   },
   {
      dataField: 'options',
      text: 'Opciones',
      isDummyField: true,
      headerAlign: 'center',
      align: 'center',
      headerClasses: 'font-weight-bold text-dark',
      formatter: optionsFormatter
   }
];

function optionsFormatter(cell, row, rowIndex) {
   return (
      <Link className='btn btn-primary btn-sm mr-2 detalles-font-size text-capitalize' to={`/r/quejas/${row.idQueja}/detalles`}>
         Ver más
      </Link>
   );
}

function estatusBadgeFormatter(estatus) {
   switch (estatus) {
      case 1:
         return <MDBBadge color='danger px-4'>Pendiente</MDBBadge>;
      case 2:
         return <MDBBadge color='warning px-4'>En seguimiento</MDBBadge>;
      case 3:
         return <MDBBadge color='success px-4'>Finalizado</MDBBadge>;
   }
}

function ratingFormatter(rating: number) {
   return <Rating initialRating={rating} readonly emptySymbol='far fa-star fa-lg mr-1' fullSymbol='fas fa-star fa-lg mr-1' />;
}

export interface QuejasTablaProps {
   quejas: any[];
   isTableLoading: boolean;
   pagination: Pagination;
   handleTableChange: (
      type: any,
      {
         page,
         sizePerPage
      }: {
         page: any;
         sizePerPage: any;
      }
   ) => void;
}

const QuejasTabla: React.SFC<QuejasTablaProps> = ({ quejas, isTableLoading, pagination, handleTableChange }) => {
   return (
      <BootstrapTable
         bootstrap4
         condensed
         classes='quejas-table'
         keyField='idQueja'
         data={quejas}
         columns={columns}
         remote={{
            pagination: true
         }}
         loading={isTableLoading}
         overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
         hover
         bordered={false}
         noDataIndication='No se encontraron registros.'
         rowClasses={'font-weight-bold'}
         pagination={paginationFactory({
            page: pagination.page, // Specify the current page.
            totalSize: pagination.totalSize, // Total data size.
            sizePerPage: pagination.sizePerPage, // Specify the size per page.
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true
         })}
         onTableChange={handleTableChange}
      />
   );
};

export default memo(QuejasTabla);
