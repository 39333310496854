import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import FormConfiguracion from '../configuracion/FormConfiguracion';

export interface ConfigVariablesPageProps {}

const ConfigVariablesPage: React.FC<ConfigVariablesPageProps> = () => {
   return (
      <section id='config'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0 text-center'>Administrar variables del sistema</h3>
               </header>
            </MDBCol>
         </MDBRow>

         {/* FORM DE CONFIGURACION */}
         <FormConfiguracion />
      </section>
   );
};

export default ConfigVariablesPage;
