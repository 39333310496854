import React, { Fragment } from 'react';

export interface ToastMessageProps {
   type: string;
}

const ToastMessage: React.FC<ToastMessageProps> = ({ type, children }) => {
   const getMessageType = () => {
      switch (type) {
         case 'success':
            return '!Éxito!';
         case 'error':
            return '!Error!';
         case 'info':
            return 'Aviso';
         case 'warning':
            return '!Advertencia!';
      }
   };

   return (
      <Fragment>
         <p className='font-weight-bold mb-1 pl-2'>{getMessageType()}</p>
         <div className='pl-2'>{children}</div>
      </Fragment>
   );
};

export default ToastMessage;
