import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBAlert, MDBIcon } from 'mdbreact';
import ErrorFeedback from 'components/shared/ErrorFeedback';
import { updateAbilities } from 'services/authorization.service';
import { storeToken } from 'services/authentication.service';
import http from 'services/http.service';
import { Alert } from 'typings/General';

const Login: React.FC = () => {
   const [toDashboard, setToDashboard] = useState<boolean>(false);
   const [errorAlert, setErrorAlert] = useState<Alert>({
      isOpen: false,
      message: ''
   });
   // const [showPassword, setShowPassword] = useState<boolean>(false);
   const initialValues = {
      email: '',
      password: ''
   };

   const loginSchema = Yup.object().shape({
      email: Yup.string()
         .email('Escriba un email válido')
         .required('Campo requerido'),
      password: Yup.string()
         .min(6, 'Mínimo 6 caracteres')
         .max(50, 'Maximo 50 caracteres')
         .required('Campo requerido')
   });

   // const toggleShowPassword = () => {
   //    setShowPassword(prevState => !prevState);
   // };

   const handleFormSubmit = async (values, actions) => {
      try {
         const body = {
            email: values.email,
            password: values.password
         };
         const resp: any = await http.post('auth/login', body);
         storeToken(resp.token);
         updateAbilities();
         setToDashboard(true);
      } catch (error) {
         actions.setSubmitting(false);
         if (error.type === 'Auth/BadEmailOrPassword') {
            setErrorAlert({
               isOpen: true,
               message: error.message
            });
         }
      }
   };

   const handleCloseAlert = () => {
      setErrorAlert({
         isOpen: false,
         message: ''
      });
   };

   if (toDashboard) {
      return <Redirect to='/r/monitor' />;
   }

   return (
      <div className='text-center'>
         {errorAlert.isOpen ? (
            <MDBAlert className='text-left' color='danger' dismiss onClose={handleCloseAlert}>
               {errorAlert.message}
            </MDBAlert>
         ) : null}
         <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={handleFormSubmit}
            render={({ isSubmitting, setFieldValue }: FormikProps<any>) => (
               <Form className='login-form'>
                  <Field
                     name='email'
                     render={({ field }) => (
                        <MDBInput
                           icon='at'
                           outline
                           label='Correo electrónico'
                           type='text'
                           {...field}
                           onChange={e => setFieldValue(field.name, e.target.value.trim())}
                        />
                     )}
                  />
                  <ErrorMessage name='email' component={ErrorFeedback} />
                  <Field
                     name='password'
                     render={({ field }) => <MDBInput icon='key' outline label='Contraseña' type='password' {...field} />}
                  />
                  <ErrorMessage name='password' component={ErrorFeedback} />

                  <MDBBtn color='default' type='submit' className='btn-login mt-4' block disabled={isSubmitting}>
                     {isSubmitting ? <MDBIcon icon='spinner' pulse size='lg' /> : 'Iniciar Sesión'}
                  </MDBBtn>
               </Form>
            )}
         />

         <Link to='/login/recuperar-password' className='d-block text-kargo mt-5 mb-3 forgot-link'>
            ¿Olvidaste tu contraseña?
         </Link>

         <Link to='/login/recuperar-password' className='d-block help-link'>
            ¿Necesitas ayuda o más información?
         </Link>

         <hr />

         <MDBRow>
            <MDBCol size='6'>
               <p className='text-kargo mb-0'>
                  <small>Sitio de ayuda</small>
               </p>
            </MDBCol>
            <MDBCol size='6'>
               <p className='text-kargo mb-0'>
                  <small>Aviso de privacidad</small>
               </p>
            </MDBCol>
         </MDBRow>
      </div>
   );
};

export default Login;
