import React, { forwardRef } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import { Map, TileLayer, Polygon } from 'react-leaflet';
// Mis Componentes
import ErrorFeedback from 'components/shared/ErrorFeedback';
import SelectInput from 'components/shared/SelectInput';
// Mis Types
import { Option } from 'typings/General';
import { Grupo } from 'models/Grupos';

interface InformacionGrupoProps {
   grupo: Grupo;
   zonas: Option[];
   map: any;
   fetchZona: (id: number) => Promise<void>;
   onStepSubmit: (values, actions) => void;
}

const InformacionGrupo: React.FC<InformacionGrupoProps> = ({ grupo, zonas, map, fetchZona, onStepSubmit }, ref) => {
   const initialValues = { ...grupo };
   const informacionZonaSchema = Yup.object().shape({
      nombre: Yup.string().required('Campo requerido'),
      idChoferLider: Yup.number().nullable(),
      idZona: Yup.number()
         .nullable()
         .required('Campo requerido')
   });

   return (
      <Formik
         initialValues={initialValues}
         enableReinitialize={true}
         validationSchema={informacionZonaSchema}
         onSubmit={onStepSubmit}
         render={({ setFieldValue }: FormikProps<any>) => (
            <Form>
               <p className='text-muted mb-1 font-size-18'>Información básica</p>

               <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                     <Field
                        name='nombre'
                        render={({ field }) => <MDBInput {...field} outline label='Nombre de grupo' type='text' />}
                     />
                     <ErrorMessage name='nombre' component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md='4'>
                     <Field
                        name='idZona'
                        render={({ field }) => (
                           <SelectInput
                              {...field}
                              placeholder='Elegir Zona de operación'
                              options={zonas}
                              isSearchable={false}
                              setFieldValue={setFieldValue}
                              fetchRelatedFieldData={fetchZona}
                              doFetchRelatedField={true}
                           />
                        )}
                     />
                     <ErrorMessage name='idZona' component={ErrorFeedback} />
                  </MDBCol>
               </MDBRow>

               {/* MAPA */}
               <Map ref={ref} center={map.position} zoom={map.zoom} animate>
                  <TileLayer
                     attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                     url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <Polygon positions={map.polygonPoints} />
               </Map>

               <MDBRow className='mt-5 justify-content-center'>
                  <MDBCol md='4'>
                     <MDBBtn color='default' type='submit' className='font-weight-medium mx-0 px-5' block>
                        Siguiente
                     </MDBBtn>
                  </MDBCol>
               </MDBRow>
            </Form>
         )}
      />
   );
};

export default forwardRef(InformacionGrupo);
