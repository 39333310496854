import React from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Reportes from 'components/reportes/Reporte';

export interface ReportesPageProps {}

const ReportesPage: React.FC<ReportesPageProps> = () => {
   return (
      <section id='clientes'>
         <MDBRow className='mb-4 align-items-center'>
            <MDBCol>
               <header>
                  <h3 className='mb-0'>Generar reportes</h3>
               </header>
            </MDBCol>
         </MDBRow>

         <MDBCard>
            <div className='card-header bg-dark-orange text-white font-weight-bold'>
               <span>
                  <MDBIcon icon='file-alt' className='mr-3' />
                  Reportes
               </span>
            </div>
            <MDBCardBody className=''>
               <Reportes />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default ReportesPage;
